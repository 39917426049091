import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";

const ACTIVE_INDICATOR = {
  1: "Active",
  0: "Inactive",
};

const STATUS = {
  1: "draft",
  2: "In Review",
  3: "Published",
};

const Card = ({
  title,
  description,
  img,
  category,
  date,
  tags,
  author,
  showBtn,
  link,
  isExternal,
  isPublic,
  onDelete,
  onEdit,
  extras,
}) => {
  return (
    <div className="card shadow-sm">
      {isPublic && (
        <div
          className="mt-2 mb-2"
          style={{
            fontSize: "18px",
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
            padding: "10px 10px",
          }}
        >
          <i className="fa-solid fa-pen-to-square fa-lg" onClick={onEdit}></i>
          <i
            className="fa-solid fa-trash  fa-lg"
            style={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={onDelete}
          ></i>
        </div>
      )}
      <div className="d-flex">
        <img loading="lazy" src={img} alt="Image Not Found" />
      </div>
      <div className="card-body">
        <h4 className="panel-youth">
          <a
            href=""
            rel="noopener noreferrer"
            onClick={(e) => e.preventDefault()}
          >
            {title}
          </a>
        </h4>
        {date && (
          <div className="posted-date">
            <i className="fa-solid fa-calendar-days"></i>
            <span>Posted On: {date}</span>
          </div>
        )}
        {category && (
          <div className="rural-indastr">
            Category:<span>{category}</span>
          </div>
        )}
        <p className="card-text">{description}</p>

        {isPublic && (
          <div>
            <Badge bg="dark" text="light" className="p-2 mx-1">
              {extras && extras.publishStage && STATUS[extras.publishStage]}
            </Badge>
            <Badge bg="dark" text="light" className="p-2 mx-1">
              {extras && ACTIVE_INDICATOR[extras.active_indicator]}
            </Badge>
          </div>
        )}
      </div>
      <div className="card-footer">
        {" "}
        <div className="eng-btn">
          {tags && tags.map((tag) => <h5 key={tag}>{tag}</h5>)}
        </div>
        {author && (
          <div className="Instructor row">
            <div className="Jose col-7">
              <p>
                <span>Author:</span>
              </p>
              <p translate="no">- {author}</p>
            </div>
            {isExternal ? (
              <a
                href={link}
                target="_blank"
                className="btn btn-pink"
                translate="no"
                rel="noopener noreferrer"
              >
                External Link
              </a>
            ) : (
              <Link to={link} className="btn btn-pink col-5">
                Read More
              </Link>
            )}
          </div>
        )}
        {showBtn &&
          (isExternal ? (
            <a
              href={link}
              target="_blank"
              className="btn btn-pink"
              style={{ width: "100%", marginBottom: "10px" }}
              translate="no"
              rel="noopener noreferrer"
            >
              External Link
            </a>
          ) : (
            <Link
              to={link}
              className="btn btn-pink"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              Read More
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Card;
