import React from "react";
import { extractShortDescription, formatDate } from "../utility/helpers";

const DocsPreview = ({ form }) => {
  const toHtml = (text) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(text, "text/html");
    return html.body.textContent;
  };
  return (
    <div id="preview">
      {/* <Card
        title={form.title}
        img={
          typeof form.bannerImageURL === "string" && form.bannerImageURL.length
            ? process.env.REACT_APP_BUCKET_URL + form.bannerImageURL
            : typeof form.bannerImage === "object"
            ? URL.createObjectURL(form.bannerImage)
            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
        }
        category={form.category}
        showBtn={true}
        description={extractShortDescription(form.description, 50)}
        link={""}
      /> */}
      <div className="preview_image_wrapper">
        <img
          src={
            typeof form.bannerImageURL === "string" &&
            form.bannerImageURL.length
              ? process.env.REACT_APP_BUCKET_URL + form.bannerImageURL
              : typeof form.bannerImage === "object"
              ? URL.createObjectURL(form.bannerImage)
              : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
          }
        ></img>
      </div>
      <div className="preview_title_wrapper " style={{ marginTop: "5rem" }}>
        <h2 style={{ margin: "0" }}>{form?.title}</h2>
      </div>
      <div className="posted-date">
        <i className="fa-solid fa-calendar-days"></i>
        <span>
          Posted On:{" "}
          <span>{form.date && formatDate(form.date).toString()}</span> | Posted
          By: <span>{form?.author || form?.authorName}</span>
        </span>
      </div>
      <div className=" mb-2">
        <div className="smile-photo">
          <embed
            src={form.FileURL}
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="700px"
            width="100%"
          ></embed>
        </div>
      </div>
      <div className="preview_description_wrapper">
        <div className="description">
          <h2 className="mt-5">Description</h2>
          <span>{toHtml(form.description)}</span>
        </div>
      </div>
    </div>
  );
};

export default DocsPreview;
