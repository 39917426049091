import { useState, useEffect } from "react";
import { offset } from "@floating-ui/dom";

const NavStep = {
  id: "intro",
  attachTo: { element: "#navbar-toggler", on: "top" },
  text: ["Click on Nav icon to explore."],
  cancelIcon: {
    enabled: true,
  },
  buttons: [],
  showOn: () => {
    if (
      window.innerWidth <= 1200 &&
      document.getElementById("navbar-toggler") &&
      document
        .getElementById("navbar-toggler")
        .getAttribute("aria-expanded") === "false"
    )
      return true;
    return false;
  },
  advanceOn: {
    selector: "#navbar-toggler",
    event: "click",
  },
};

const SignUpStep = {
  id: "intro",
  attachTo: { element: "#sign-up", on: "top" },
  text: ["Join our platform to access exclusive features and benefits. Create, share, and collaborate through resources, media, and chat groups."],
  title: "Sign Up",
  buttons: [
    {
      classes: "shepherd-button-primary",
      text: "Back",
      type: "back",
    },
    {
      classes: "shepherd-button-secondary",
      text: "End",
      type: "cancel",
    },
  ],
  showOn: () => {
    if (document.getElementById("sign-up")) return true;
    return false;
  },
  canClickTarget: false,
};

const homeSteps = [
  NavStep,
  {
    id: "intro",
    attachTo: { element: "#media_step", on: "top" },
    text: ["Explore diverse OVOC multimedia content like photos, videos, social media, and live streaming for a rich and immersive experience."],
    title: "Media",
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 0, crossAxis: 12 })],
    },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#ovoc_in_action_step", on: "top" },
    text: ["Engage in dynamic events, webinars, community chats, and stay informed with the latest news and updates in the 'OVOC in Action' section."],
    title: "Ovoc in Action",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#campaigns_step", on: "top" },
    text: ["Engage in impactful advocacy through our OVOC-supported campaigns and petitions."],
    title: "Campaigns",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#learning_resources_step", on: "top" },
    text: ["Dive into a wealth of knowledge with our diverse array of resources, including blogs, articles, tools, case stories, reports, and research documents."],
    title: "Learning Resources",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        action() {
          if (
            window.innerWidth <= 1200 &&
            document.getElementById("navbar-toggler") &&
            document
              .getElementById("navbar-toggler")
              .getAttribute("aria-expanded") === "true"
          )
            document.getElementById("navbar-toggler").click();
          this.next();
        },
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#select-country", on: "top" },
    text: ["Tailor your experience by selecting your country for country-specific content."],
    title: "Choose Country",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        action() {
          if (
            window.innerWidth <= 1200 &&
            document.getElementById("navbar-toggler") &&
            document
              .getElementById("navbar-toggler")
              .getAttribute("aria-expanded") === "false"
          )
            document.getElementById("navbar-toggler").click();
          this.back();
        },
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#select-language", on: "top" },
    text: ["Choose your preferred language for a personalized browsing experience."],
    title: "Choose Language",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#news_step", on: "top" },
    text: ["Stay informed with news and updates on OVOC activities tailored for affiliated CSOs."],
    title: "News",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#blogs_step", on: "top" },
    text: ["Delve into insightful reads from our blog and articles section."],
    title: "Blogs & Articles",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#events_step", on: "top" },
    text: ["Engage and explore OVOC-related events and webinars for active participation."],
    title: "Events",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#chatboard_step", on: "top" },
    text: ["Connect with others and join discussions in our community chat."],
    title: "Chatboard",
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  {
    id: "intro",
    attachTo: { element: "#newsletter_step", on: "top" },
    title: "Subscribe Newsletter",
    text: ["Stay updated with the latest developments by subscribing to our newsletter."],
    beforeShowPromise: function () {
      this.buttons[1].text = document.getElementById("sign-up")
        ? "Next"
        : "End";
      return new Promise(function (resolve) {
        resolve();
      });
    },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: true,
    },
    canClickTarget: false,
  },
  SignUpStep,
];

export { homeSteps, NavStep, SignUpStep };
