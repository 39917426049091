import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Compressor from "compressorjs";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
} from "react-bootstrap";
import { useLoading } from "../context/LoadingContext";
import axiosInstance from "../utility/axios";
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import DocsPreview from "../components/DocsPreview";
import Notification from "../components/Notification";

const PreviewModal = ({ show, setShow, form }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Resource Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal_body">
        <DocsPreview form={form} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function CreateDocs() {
  const [countries, setCountries] = useState([]);
  const [fileError, setFileError] = useState("");
  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [show, setShow] = useState(false);
  const { setLoading } = useLoading();
  const [notificationData, setNotificationData] = useState([]);
  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });
  const docs_category = ["Knowledge", "Information", "Global", "General"];
  const docs_types = ["tools", "document", "report"];
  const docs_types_name = {
    tools: "Tools",
    document: "Document",
    report: "Report",
  };
  const [form, setForm] = useState({
    id: "",
    title: "",
    date: "",
    comments: [],
    bannerImage: "",
    bannerImageURL: "",
    bannerImageExt: "",
    bannerImageId: "",
    File: "",
    FileName: "",
    FileURL: "",
    FileId: "",
    FileExt: "",
    description: "",
    countryIds: [],
    category: "",
    docs_type: "",
    publishStage: undefined,
    authorName: "",
  });

  const { user } = useUserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("date", form.date);
    formData.append("bannerImage", form.bannerImage);
    formData.append("description", form.description);
    formData.append("category", form.category);
    formData.append("docs_type", form.docs_type);
    formData.append("authorName", form.authorName);
    formData.append(
      "countryIds",
      JSON.stringify(form.countryIds.map((country) => country.id))
    );
    formData.append("FileURL", form.FileURL);
    formData.append("FileName", form.FileName);
    formData.append("FileExt", form.FileExt);
    formData.append("FileId", form.FileId);

    if (form.id && idToken) {
      formData.append("bannerImageId", form.bannerImageId);
      await axiosInstance
        .patch(
          `/website/edit/docs/${form.id}/${user?.userCustomData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + idToken,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // setBacsImageTracker([]);
            setMsg({
              message: [],
              variant: "danger",
            });

            navigate("/my-resources");
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({
                message: [...response.data.error],
                variant: "danger",
              });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (idToken) {
      await axiosInstance
        .post(`/website/create/docs/${user?.userCustomData?.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // setBacsImageTracker([]);
            setMsg({
              message: [],
              variant: "danger",
            });
            navigate("/my-resources");
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({ message: [...response.data.error], variant: "danger" });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLoading(false);
  };
  const handleBack = () => {
    const query = new URLSearchParams(location.search);
    const isEdit = query.get("id");
    if (isEdit) {
      navigate("/my-resources");
    } else {
      navigate("/");
    }
  };

  const uploadFileToFirebase = (e, uploadUrl) => {
    return new Promise(async (resolve, reject) => {
      const file = e.target.files[0];
      try {
        const storage = getStorage();
        const storageRef = ref(storage, uploadUrl);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            switch (snapshot.state) {
              case "paused":
                // console.log("Upload is paused");
                break;
              case "running":
                // console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // console.log("Upload error", error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              // console.log("File available at", downloadURL);
              resolve(downloadURL); // Resolve the promise with the downloadURL
            } catch (error) {
              // console.log("Error getting download URL", error);
              reject(error);
            }
          }
        );
      } catch (error) {
        // console.log("Video upload error", error);
        reject(error);
      }
      // signOut(auth);
    });
  };

  const uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  };
  const fetchCountries = () => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  };
  const handleDropdownSelect = (e, selectedCountry) => {
    if (user?.userCustomData?.countryId !== selectedCountry.id) {
      let prevSelected = form?.countryIds || [];
      let selectedCountries = [];
      // Check if the country is already selected
      const isSelected = prevSelected.filter(
        (country) => country.id === selectedCountry.id
      );
      // If the country is already selected, remove it from the selected list
      if (isSelected.length) {
        selectedCountries = prevSelected.filter(
          (country) => country.id !== selectedCountry.id
        );
      } else {
        // If the country is not selected, add it to the selected list
        selectedCountries = [...prevSelected, selectedCountry];
      }
      handleChange({
        target: { name: "countryIds", value: selectedCountries },
      });
    }
  };
  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setForm({ ...form, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const handleContentChange = (event, editor) => {
    setForm((prevForm) => ({ ...prevForm, description: editor.getData() }));
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setForm({ ...form, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        setForm({ ...form, [e.target.name]: compressedFile });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };
  const removeSelectedFileFirebase = async (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";
    if ((form[name] && typeof form[name] !== "string") || form.FileId) {
      const storage = getStorage();
      const storageRef = ref(storage, `public/docs/files/${form.FileId}`);

      try {
        await deleteObject(storageRef);
        // console.log("File deleted from Firebase Storage");
      } catch (error) {
        console.error("Error deleting file from Firebase Storage:", error);
      }
      // signOut(auth);
    }
    setUploadProgress(0);
    // setNotificationData([
    //   {
    //     msg: "Document removed",
    //     status: 200,
    //   },
    // ]);
    setForm({
      ...form,
      [name]: "",
      FileURL: "",
      FileId: "",
      FileName: "",
      FileExt: "",
    });
    setFileError({ ...fileError, [name]: "" });
  };
  const handleFileChange = async (e, uploadFileToFirebase) => {
    const file = e.target.files[0];
    if (
      file &&
      ["application/pdf"].includes(file.type) &&
      file.size <= 15000000
    ) {
      const FileName = file.name;
      const FileId = `${Date.now()}_${file.name}`;
      const uploadUrl = `public/docs/files/${FileId}`;
      const FileExt = FileName.substring(FileName.lastIndexOf("."));

      const downloadURL = await uploadFileToFirebase(e, uploadUrl);
      setForm((form) => {
        return {
          ...form,
          [e.target.name]: file,
          FileURL: downloadURL,
          FileId,
          FileName,
          FileExt,
        };
      });
      setFileError({ ...fileError, [e.target.name]: "" });
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 15MB",
      });
    }
  };

  const fetchDocsData = async (id) => {
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    if (idToken) {
      await axiosInstance
        .get(`/website/get/docs/${id}/${user?.userCustomData?.id}`, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setForm({
              id: res.data.id,
              title: res.data.title,
              date: res.data.date,
              bannerImage: "",
              bannerImageURL: res.data.bannerImageURL,
              bannerImageExt: res.data.bannerImageExt,
              bannerImageName: res.data.bannerImageName,
              bannerImageId: res.data.bannerImageId,
              description: res.data.description,
              countryIds: res.data.Countries.map((country) => {
                return {
                  id: country.id,
                  name: country.name,
                };
              }),
              File: "",
              category: res.data.category,
              authorId: res.data.authorId,
              authorName: res.data.authorName,
              authorImage: res.data.authorImage,
              authorDescription: res.data.authorDescription,
              publishStage: res.data.publishStage,
              docs_type: res.data.docs_type,
              FileName: res.data.FileName,
              FileId: res.data.FileId,
              FileURL: res.data.FileURL,
              FileExt: res.data.FileExt,
            });
          } else {
            setForm({
              id: "",
              title: "",
              date: "",
              bannerImage: "",
              bannerImageURL: "",
              bannerImageExt: "",
              bannerImageId: "",
              description: "",
              countryIds: [],
              category: "",
              bacs_type: "",
              File: "",
              publishStage: undefined,
              authorName: "",
              authorDescription: "",
              authorImage: "",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get("id");
    const type = query.get("type");
    if (id && type === "docs") {
      fetchDocsData(id);
    } else {
      setForm({
        id: "",
        title: "",
        date: "",
        comments: [],
        bannerImage: "",
        bannerImageURL: "",
        bannerImageExt: "",
        bannerImageId: "",
        File: "",
        FileName: "",
        FileURL: "",
        FileId: "",
        FileExt: "",
        description: "",
        countryIds: [],
        category: "",
        docs_type: "",
        publishStage: undefined,
        authorName: "",
      });
    }
    fetchCountries();
  }, [user, location.search]);

  useEffect(() => {
    let selectedCountries = [];
    if (countries.length) {
      if (
        form.countryIds.find(
          (country) => country.id === user?.userCustomData.countryId
        ) ||
        !user?.userCustomData.countryId
      ) {
        selectedCountries = form.countryIds;
      } else {
        const id = user?.userCustomData.countryId;
        const name = countries.find((c) => c.id === id)?.name;
        selectedCountries = [
          ...form.countryIds,
          {
            id,
            name,
          },
        ];
      }
      handleChange({
        target: { name: "countryIds", value: selectedCountries },
      });
    }
  }, [form.countryIds, countries]);

  return (
    <div>
      <PreviewModal show={show} setShow={setShow} form={form} />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="form_top">
        <div className="editform">
          <h2></h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <button className="top_btn" onClick={() => setShow(true)}>
              Preview
            </button>
          </div>
          <div>
            <button
              className="top_btn"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Save
            </button>
          </div>
          <div>
            <button className="top_btn" onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-md-8 left" style={{ position: "relative" }}>
          <div className="blog_title">
            <h4>Title</h4>
            {form.publishStage != 2 && <span>*Required</span>}

            <input
              type="text"
              className="form-control"
              id="blogTitle"
              name="title"
              placeholder=""
              value={form.title}
              onChange={handleChange}
              disabled={form.publishStage === 2}
            />
          </div>
          <div className="upload_file_wrapper" style={{ position: "relative" }}>
            <h4>Upload File</h4>
            {form.publishStage != 2 && (
              <span>*File can have max size of 15MB </span>
            )}
            <div
              className="image-uploader"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                border: "3px dashed #ccc",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              <label htmlFor="File" className="upload-label">
                <span className="upload-text">
                  {form.File || form.FileId
                    ? typeof form.File === "string"
                      ? `${form.FileName}`
                      : form.File.name
                    : "Choose from files"}
                </span>{" "}
                {(form.File || form.FileId) && (
                  <button
                    style={{
                      padding: "0px .5em",
                      marginLeft: "1em",
                    }}
                    className="remove-button"
                    type="button"
                    onClick={() => removeSelectedFileFirebase("File")}
                    disabled={form.publishStage === 2}
                  >
                    X
                  </button>
                )}
              </label>

              <input
                type="file"
                id="File"
                accept="application/pdf"
                multiple={false}
                name="File"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileChange(e, uploadFileToFirebase);
                }}
                disabled={form.publishStage === 2}
              />
            </div>
            <div
              className="progress_bar_wrapper"
              style={{
                marginTop: "1em",
              }}
            >
              <div
                className="progress_bar"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <div className="progress-text">
              {uploadProgress !== 0 && `${uploadProgress.toFixed(2)}%`}
            </div>
            <div>
              {fileError && fileError?.File && (
                <Alert variant="danger">{fileError?.File}</Alert>
              )}
            </div>
          </div>

          <div className="banner_image">
            <h4>Upload Banner Image</h4>
            {form.publishStage != 2 && (
              <span>*File can have max size of 5MB </span>
            )}
            <div
              className="image-uploader"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                border: "3px dashed #ccc",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              <label htmlFor="bannerImage" className="upload-label">
                <span className="upload-text">
                  {form.bannerImage || form.bannerImageName
                    ? typeof form.bannerImage === "string"
                      ? form.bannerImageName
                      : form.bannerImage.name
                    : "Choose from files"}
                </span>{" "}
                {form.bannerImage && (
                  <button
                    style={{
                      padding: "0px .5em",
                      marginLeft: "1em",
                    }}
                    className="remove-button"
                    type="button"
                    onClick={() => removeSelectedFile("bannerImage")}
                  >
                    X
                  </button>
                )}
              </label>

              <input
                type="file"
                id="bannerImage"
                accept=".jpg,.jpeg,.png"
                multiple={false}
                style={{ display: "none" }}
                name="bannerImage"
                onChange={handleImageChange}
                disabled={form.publishStage === 2}
              />
            </div>
            <div>
              {fileError && fileError?.bannerImage && (
                <Alert variant="danger">{fileError?.bannerImage}</Alert>
              )}
            </div>
          </div>
          <div className="author_wrapper marginB">
            <h4>Authors</h4>
            <input
              type="text"
              className="form-control"
              id="authorName"
              name="authorName"
              placeholder=""
              value={form.authorName}
              onChange={handleChange}
              disabled={form.publishStage === 2}
            />
          </div>
          <div className="description_wrapper ">
            <h4>Description</h4>
            {form.publishStage != 2 && <span>*Required</span>}
            <div className="row">
              <CKEditor
                editor={Editor}
                data={form.description}
                onReady={(editor) => {
                  uploadPlugin(editor);
                }}
                onChange={handleContentChange}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
                disabled={form.publishStage === 2}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 right">
          <div className="editform_right">
            <div className="country marginB">
              <h4>Country</h4>
              {form.publishStage != 2 && (
                <p style={{ margin: "0" }}>*Required</p>
              )}
              {form.publishStage == 2 ? (
                <>
                  {form.countryIds.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={form?.countryIds.length === countries.length}
                    onChange={() => {
                      let selectedCountries =
                        form?.countryIds.length === countries.length
                          ? []
                          : countries;
                      handleChange({
                        target: {
                          name: "countryIds",
                          value: selectedCountries,
                        },
                      });
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={form?.countryIds.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                    />
                  ))}
                </DropdownButton>
              )}
            </div>
            <div className="Category marginB">
              <h4>Category</h4>
              {form.publishStage != 2 && <span>*Required</span>}
              <DropdownButton
                variant="outline-secondary"
                title={form.category ? form.category : "Select Category"}
                onSelect={(e) => {
                  handleChange({
                    target: { name: "category", value: e },
                  });
                }}
                id="input-group-dropdown-1"
                disabled={form.publishStage === 2}
              >
                {docs_category.map((docsCategory, index) => (
                  <Dropdown.Item eventKey={docsCategory} key={index}>
                    {docsCategory}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="marginB">
              <h4>Resource Type</h4>
              {form.publishStage != 2 && <span>*Required</span>}
              <DropdownButton
                variant="outline-secondary"
                title={
                  form.docs_type
                    ? docs_types_name[form.docs_type]
                    : "Resource Type"
                }
                onSelect={(e) => {
                  handleChange({
                    target: { name: "docs_type", value: e },
                  });
                }}
                disabled={form.publishStage === 2}
              >
                {docs_types.map((type, index) => (
                  <Dropdown.Item eventKey={type} key={index}>
                    {docs_types_name[type]}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="postDate_wrapper marginB">
              <h4>Post date</h4>
              {form.publishStage != 2 && <span>*Required</span>}
              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="date"
                placeholder=""
                // min={new Date().toISOString().split("T")[0]}
                value={form.date}
                onChange={handleChange}
                disabled={form.publishStage === 2}
              />
            </div>
          </div>
          <div
            style={{
              display: form.publishStage === 2 ? "flex" : "none",
            }}
          >
            <Card border="primary" style={{ margin: "auto" }}>
              <Card.Header>{"Please Wait!!!"}</Card.Header>
              <Card.Body>
                <Card.Text as="div">{"Blog is pending for approval"}</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div>
            {msg.message.length > 0 &&
              msg.message.map((m, index) => (
                <Alert key={index} variant={msg.variant}>
                  {m}
                </Alert>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
