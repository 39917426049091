import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
  Button,
} from "react-bootstrap";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useUserAuth } from "../context/UserAuthContext";
import Notification from "../components/Notification";
import moment from "moment";
import axiosInstance from "../utility/axios";
import Compressor from "compressorjs";
import { useLoading } from "../context/LoadingContext";
import { useNavigate } from "react-router-dom";
import EventPreview from "../components/EventPreview";

const PreviewModal = ({ show, setShow, form }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Event Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal_body">
        <EventPreview form={form} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function CreateEvent({ countries }) {
  const { user } = useUserAuth();
  const [event, setEvent] = useState({
    title: "",
    category: undefined,
    start: "",
    end: "",
    bannerImage: "",
    bannerImageId: "",
    description: "",
    countryIds: [],
    isOffline: false,
    place: "",
    website: "",
    phone: "",
    email: "",
    host1Name: "",
    host1Image: "",
    host1Role: "",
    host2Name: "",
    host2Image: "",
    host2Role: "",
    host3Name: "",
    host3Image: "",
    host3Role: "",
    host4Name: "",
    host4Image: "",
    host4Role: "",
    calendar_type: "event",
  });
  const [show, setShow] = useState(false);
  const [fileError, setFileError] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();
  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });
  const { setLoading } = useLoading();

  const categories = [
    "Knowledge Session",
    "Workshop",
    "Celebration",
    "General Meetup",
  ];

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get("id");
    const type = query.get("type");
    if (id && type === "event") {
      fetchEventData(id);
    } else {
      setEvent({
        title: "",
        category: undefined,
        start: "",
        end: "",
        bannerImage: "",
        bannerImageId: "",
        description: "",
        countryIds: [],
        isOffline: false,
        place: "",
        website: "",
        phone: "",
        email: "",
        host1Name: "",
        host1Image: "",
        host1Role: "",
        host2Name: "",
        host2Image: "",
        host2Role: "",
        host3Name: "",
        host3Image: "",
        host3Role: "",
        host4Name: "",
        host4Image: "",
        host4Role: "",
        calendar_type: "event",
      });
    }
  }, [user, location.search]);

  const fetchEventData = async (id) => {
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    if (idToken) {
      await axiosInstance
        .get(`/website/get/event/${id}`, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setEvent({
              id: res.data.event.id,
              title: res.data.event?.title,
              category: res.data.event?.category,
              start: res.data.event?.start,
              end: res.data.event?.end,
              bannerImageId: res.data.event?.bannerImageId,
              description: res.data.event?.description,
              isOffline: res.data.event?.isOffline,
              place: res.data.event?.place,
              website: res.data.event?.website,
              phone: res.data.event?.pnone,
              countryIds: res.data.event.countries,
              email: res.data.event?.email,
              host1Name: res.data.event?.host1Name,
              host1Image: res.data.event?.host1Image,
              host1Role: res.data.event?.host1Role,
              host2Name: res.data.event?.host2Name,
              host2Image: res.data.event?.host2Image,
              host2Role: res.data.event?.host2Role,
              host3Name: res.data.event?.host3Name,
              host3Image: res.data.event?.host3Image,
              host3Role: res.data.event?.host3Role,
              host4Name: res.data.event?.host4Name,
              host4Image: res.data.event?.host4Image,
              host4Role: res.data.event?.host4Role,
              publishStage: res.data.event?.publishStage,
              calendar_type: res.data.event?.calendar_type,
            });
          } else {
            setEvent({
              title: "",
              category: undefined,
              start: "",
              end: "",
              bannerImage: "",
              bannerImageId: "",
              description: "",
              countryIds: [],
              isOffline: false,
              place: "",
              website: "",
              phone: "",
              email: "",
              host1Name: "",
              host1Image: "",
              host1Role: "",
              host2Name: "",
              host2Image: "",
              host2Role: "",
              host3Name: "",
              host3Image: "",
              host3Role: "",
              host4Name: "",
              host4Image: "",
              host4Role: "",
              calendar_type: "event",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChange = (e) => {
    if (e.target.id === "isOffline") {
      setEvent({ ...event, [e.target.id]: e.target.checked });
    } else {
      setEvent({ ...event, [e.target.id]: e.target.value });
    }
  };

  const handleContentChange = (e, editor) => {
    setEvent((prev) => ({ ...prev, description: editor.getData() }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setEvent({ ...event, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        setEvent({ ...event, [e.target.name]: compressedFile });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user?.userCustomData.countryId !== selectedCountry.id) {
      let prevSelected = event?.countryIds || [];
      let selectedCountries = [];
      // Check if the country is already selected
      const isSelected = prevSelected.filter(
        (country) => country.id === selectedCountry.id
      );
      // If the country is already selected, remove it from the selected list
      if (isSelected.length) {
        selectedCountries = prevSelected.filter(
          (country) => country.id !== selectedCountry.id
        );
      } else {
        // If the country is not selected, add it to the selected list
        selectedCountries = [...prevSelected, selectedCountry];
      }
      handleChange({
        target: { id: "countryIds", value: selectedCountries },
      });
    }
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setEvent({ ...event, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    const { publishStage, authorId, ...form } = event;
    const formData = {
      ...form,
      start: moment(form.start).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      end: moment(form.end).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      countryIds: JSON.stringify(
        selectedCountries.map((country) => country.id)
      ),
    };

    if (!event.id) {
      axiosInstance
        .post(`/website/create/event/${user?.userCustomData?.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + idToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setMsg({
              message: [],
              variant: "danger",
            });
            navigate("/my-resources");
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        });
    } else {
      axiosInstance
        .patch(`/website/event/edit/${event.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + idToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setMsg({
              message: [],
              variant: "danger",
            });
            navigate("/my-resources");
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        });
    }
    setLoading(false);
  };

  const handleBack = () => {
    const query = new URLSearchParams(location.search);
    const isEdit = query.get("id");
    if (isEdit) {
      navigate("/my-resources");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    let selectedCountries = [];
    if (countries.length) {
      if (
        event.countryIds.find(
          (country) => country.id === user?.userCustomData.countryId
        ) ||
        !user?.userCustomData.countryId
      ) {
        selectedCountries = event.countryIds;
      } else {
        const id = user?.userCustomData.countryId;
        const name = countries.find((c) => c.id === id)?.name;
        selectedCountries = [
          ...event.countryIds,
          {
            id,
            name,
          },
        ];
      }
      handleChange({
        target: { id: "countryIds", value: selectedCountries },
      });
    }
  }, [event.countryIds, countries]);

  return (
    <>
      <PreviewModal show={show} setShow={setShow} form={event} />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="form_top">
        <div className="editform">
          <h2></h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <button className="top_btn" onClick={() => setShow(true)}>
              Preview
            </button>
          </div>
          <div>
            <button className="top_btn" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div>
            <button className="top_btn" onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
      </div>

      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="event_wrapper">
          <label htmlFor="title">
            <h4>Title</h4>
            {event.publishStage != 2 && <span>*Required</span>}
          </label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="title"
              aria-label="Title"
              aria-describedby="Title"
              id="title"
              value={event.title}
              onChange={handleChange}
              disabled={event.publishStage === 2}
            />
          </InputGroup>
          <div className="event_dates">
            <div>
              <label htmlFor="start">
                <h4>Start Date</h4>
                {event.publishStage != 2 && <span>*Required </span>}
              </label>
              <input
                type="datetime-local"
                name="start-date"
                onChange={handleChange}
                value={event.start}
                id="start"
                disabled={event.publishStage === 2}
              ></input>
            </div>

            <div>
              <label htmlFor="end">
                <h4>End Date</h4>
                {event.publishStage != 2 && <span>*Required </span>}
              </label>
              <input
                type="datetime-local"
                name="end-date"
                id="end"
                onChange={handleChange}
                disabled={event.publishStage === 2}
              ></input>
            </div>
          </div>
          <div className="custom_dropdowns">
            <InputGroup>
              <DropdownButton
                variant="outline-secondary"
                title={event.category ? event.category : "Select Category"}
                id="input-group-dropdown-1"
                onSelect={(e) =>
                  handleChange({ target: { id: "category", value: e } })
                }
                disabled={event.publishStage === 2}
              >
                {categories.map((e, i) => (
                  <Dropdown.Item eventKey={e} key={i}>
                    {e}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {event?.publishStage === 2 ? (
                <>
                  {event.countryIds.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                  disabled={event.publishStage === 2}
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={event?.countryIds.length === countries.length}
                    onChange={() => {
                      let selectedCountries =
                        event?.countryIds.length === countries.length
                          ? []
                          : countries;
                      handleChange({
                        target: {
                          id: "countryIds",
                          value: selectedCountries,
                        },
                      });
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={event?.countryIds.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                      key={index}
                    />
                  ))}
                </DropdownButton>
              )}
            </InputGroup>
          </div>

          <div className="bannerImage">
            <h4>Banner Image</h4>
            <label htmlFor="File" className="upload-label">
              <span className="upload-text">
                {event.bannerImage || event.bannerImageId
                  ? typeof event.bannerImage === "object"
                    ? `${event?.bannerImage.name}`
                    : event.bannerImageId
                  : "Choose from files"}
              </span>{" "}
              {event.bannerImage && (
                <button
                  style={{
                    padding: "0px .5em",
                    marginRight: ".5em",
                    position: "relative",
                    zIndex: "5",
                  }}
                  className="remove-button"
                  type="button"
                  onClick={() => removeSelectedFile("bannerImage")}
                >
                  X
                </button>
              )}
            </label>
            <input
              type="file"
              name="bannerImage"
              id="bannerImage"
              onChange={handleImageChange}
              accept="image/*"
              disabled={event.publishStage === 2}
            ></input>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h4>Description</h4>
            {event.publishStage != 2 && <span>*Required</span>}
            <CKEditor
              editor={Editor}
              data={event.description ? event.description : ""}
              onReady={(editor) => {
                editor.ui.view.toolbar.items.get(
                  11
                ).buttonView.isVisible = false;
                // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(31).isVisible = false;
                editor.ui.view.toolbar.items.get(
                  32
                ).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(
                  33
                ).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(
                  34
                ).buttonView.isVisible = false;
                editor.ui.view.toolbar.items.get(
                  35
                ).buttonView.isVisible = false;
              }}
              onChange={handleContentChange}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
              disabled={event.publishStage === 2}
            />
          </div>

          {event.calendar_type === "event" && (
            <InputGroup className="mb-3">
              <Form.Check
                type="checkbox"
                id={"isOffline"}
                label="Is Event Offline"
                checked={event.isOffline}
                value={event.isOffline}
                onChange={handleChange}
                disabled={event.publishStage === 2}
              />
            </InputGroup>
          )}
          <InputGroup className="mb-3">
            <label htmlFor="place" style={{ width: "100%" }}>
              <h4>{event.isOffline ? "Place" : "Registration Link"}</h4>
              {event.publishStage != 2 && <span>*Required</span>}
              {/* {event.calendar_type === "webinar"
                ? "Registration Link"
                : event.isOffline
                ? "Place"
                : "Registration Link"} */}
            </label>
            <Form.Control
              placeholder={event.isOffline ? "Place" : "Registration Link"}
              aria-label="Place"
              aria-describedby="Place"
              id="place"
              value={event.place}
              onChange={handleChange}
              disabled={event.publishStage === 2}
            />
          </InputGroup>

          <div
            className="mb-3"
            style={{ background: "#ebf1f7", padding: "1rem" }}
          >
            <h4 className="mb-3">Event Details</h4>
            <div className="event_details">
              <p>
                Start Date & Time
                <span>
                  {event.start &&
                    moment(event.start).format("MMMM Do YYYY, h:mm A")}
                </span>
              </p>
            </div>
            <div className="event_details">
              <p>
                End Date & Time
                <span>
                  {event.end &&
                    moment(event.end).format("MMMM Do YYYY, h:mm A")}
                </span>
              </p>
            </div>
            <div className="event_details">
              {event.isOffline ? (
                <>
                  <p>
                    Event Venue:<span>{event.place}</span>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Event Registration Link:{" "}
                    <span>
                      {" "}
                      <a href={event.place} style={{ pointerEvents: "none" }}>
                        {event.place}
                      </a>
                    </span>
                  </p>
                </>
              )}
            </div>
            <div className="may-dum">
              <div className="organizer-event">
                <h4>Organizer</h4>
                <p>
                  <span>Phone:</span>{" "}
                  <input
                    type="text"
                    id="phone"
                    value={event.phone}
                    onChange={handleChange}
                    placeholder="contact"
                    disabled={event.publishStage === 2}
                  />
                </p>
                <p>
                  <span>Email:</span>{" "}
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    value={event.email}
                    onChange={handleChange}
                    disabled={event.publishStage === 2}
                  />
                </p>
                <p>
                  <span>Website:</span>{" "}
                  <input
                    type="text"
                    id="website"
                    placeholder="Website"
                    value={event.website}
                    onChange={handleChange}
                    disabled={event.publishStage === 2}
                  />
                </p>
              </div>
            </div>
            <div className="jesus-hoiland">
              <h4>Event Host</h4>
              <div className="event-date-r" id="host-speak">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {event.host1Image && (
                    <button
                      style={{
                        padding: "0px .5em",
                        marginRight: ".5em",
                      }}
                      className="remove-button"
                      type="button"
                      onClick={() => removeSelectedFile("host1Image")}
                    >
                      X
                    </button>
                  )}
                  <input
                    type="file"
                    name="host1Image"
                    id="host1Image"
                    onChange={handleImageChange}
                    disabled={event.publishStage === 2}
                  />

                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof event.host1Image === "string" &&
                      event.host1Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + event.host1Image
                        : typeof event.host1Image === "object" &&
                          event.host1Image !== null
                        ? URL.createObjectURL(event.host1Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    <input
                      type="text"
                      id="host1Name"
                      value={event.host1Name}
                      placeholder="name"
                      onChange={handleChange}
                      disabled={event.publishStage === 2}
                    />{" "}
                  </p>
                  {/* <span>Host and Speaker</span> */}
                  <p>
                    <input
                      type="text"
                      id="host1Role"
                      value={event.host1Role}
                      onChange={handleChange}
                      placeholder="role"
                      disabled={event.publishStage === 2}
                    />
                  </p>
                </div>
              </div>
              <div className="event-date-r" id="host-speak">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {event.host2Image && (
                    <button
                      style={{
                        padding: "0px .5em",
                        marginRight: ".5em",
                      }}
                      className="remove-button"
                      type="button"
                      onClick={() => removeSelectedFile("host2Image")}
                    >
                      X
                    </button>
                  )}
                  <input
                    type="file"
                    name="host2Image"
                    id="host2Image"
                    onChange={handleImageChange}
                    disabled={event.publishStage === 2}
                  />
                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof event.host2Image === "string" &&
                      event.host2Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + event.host2Image
                        : typeof event.host2Image === "object" &&
                          event.host2Image !== null
                        ? URL.createObjectURL(event.host2Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    <input
                      type="text"
                      id="host2Name"
                      value={event.host2Name}
                      placeholder="name"
                      onChange={handleChange}
                      disabled={event.publishStage === 2}
                    />{" "}
                  </p>
                  {/* <span>Host and Speaker</span> */}
                  <p>
                    <input
                      type="text"
                      id="host2Role"
                      value={event.host2Role}
                      onChange={handleChange}
                      placeholder="role"
                      disabled={event.publishStage === 2}
                    />
                  </p>
                </div>
              </div>
              <div className="event-date-r" id="host-speak">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {event.host3Image && (
                    <button
                      style={{
                        padding: "0px .5em",
                        marginRight: ".5em",
                      }}
                      className="remove-button"
                      type="button"
                      onClick={() => removeSelectedFile("host3Image")}
                    >
                      X
                    </button>
                  )}
                  <input
                    type="file"
                    name="host3Image"
                    id="host3Image"
                    onChange={handleImageChange}
                    disabled={event.publishStage === 2}
                  />
                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof event.host3Image === "string" &&
                      event.host3Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + event.host3Image
                        : typeof event.host3Image === "object" &&
                          event.host3Image !== null
                        ? URL.createObjectURL(event.host3Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    <input
                      type="text"
                      id="host3Name"
                      value={event.host3Name}
                      placeholder="name"
                      onChange={handleChange}
                      disabled={event.publishStage === 2}
                    />{" "}
                  </p>
                  {/* <span>Host and Speaker</span> */}
                  <p>
                    <input
                      type="text"
                      id="host3Role"
                      value={event.host3Role}
                      onChange={handleChange}
                      placeholder="role"
                      disabled={event.publishStage === 2}
                    />
                  </p>
                </div>
              </div>
              <div className="event-date-r" id="host-speak">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {event.host4Image && (
                    <button
                      style={{
                        padding: "0px .5em",
                        marginRight: ".5em",
                      }}
                      className="remove-button"
                      type="button"
                      onClick={() => removeSelectedFile("host4Image")}
                    >
                      X
                    </button>
                  )}
                  <input
                    type="file"
                    name="host4Image"
                    id="host4Image"
                    onChange={handleImageChange}
                    disabled={event.publishStage === 2}
                  />
                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof event.host4Image === "string" &&
                      event.host4Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + event.host4Image
                        : typeof event.host4Image === "object" &&
                          event.host4Image !== null
                        ? URL.createObjectURL(event.host4Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    <input
                      type="text"
                      id="host4Name"
                      value={event.host4Name}
                      placeholder="name"
                      onChange={handleChange}
                      disabled={event.publishStage === 2}
                    />{" "}
                  </p>
                  {/* <span>Host and Speaker</span> */}
                  <p>
                    <input
                      type="text"
                      id="host4Role"
                      value={event.host4Role}
                      onChange={handleChange}
                      placeholder="role"
                      disabled={event.publishStage === 2}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: event.publishStage === 2 ? "flex" : "none",
            }}
          >
            <Card border="primary" style={{ margin: "auto" }}>
              <Card.Header>{"Please Wait!!!"}</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  {"Event is pending for approval"}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div style={{ margin: "1rem" }}>
            {msg.message.length > 0 &&
              msg.message.map((m, index) => (
                <Alert key={index} variant={msg.variant}>
                  {m}
                </Alert>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
