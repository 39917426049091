import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  LANGUAGES,
  LANGMAP,
  FRONTEND_BACKEND_LANGUAGE_MAP,
} from "../utility/constants";
import { useUserAuth } from "../context/UserAuthContext";
import { useLoading } from "../context/LoadingContext";
import { MODERATOR_AVATAR_URL } from "../utility/AVATAR_URL";
import NavLinks from "../utility/NavLinks";
import { useLanguage } from "../context/LanguageContext";
import THEME from "../utility/theme";
import { NavStep, SignUpStep, homeSteps } from "../utility/steps";
import { usePreFetchData } from "../context/PreFetchDataContext";

const getCookie = (name) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null; // Cookie not found
};

const SideNavBtns = ({
  selectedLang,
  // selectedCountry,
  // handleCountrySelect,
  handleLanguageChange,
  navbar,
  user,
  moderator,
  handleLogout,
  setShowCountryBar,
  showCountryBar,
  style = {},
}) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { language } = useLanguage();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isPopupOpen && e.target.closest(".profile")) {
      } else {
        setPopupOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isPopupOpen]);

  return (
    <div style={style}>
      <ul
        id="nav-right-ican"
        className="navbar-nav ms-auto my-lg-0 navbar-nav-scroll"
      >
        <li className="nav-item dropdown dropdown-lang" id="select-country">
          <a
            className="nav-link navbar-toggler"
            rel="noopener noreferrer"
            style={{
              alignSelf: "center",
              border: "none",
            }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggleLanguageBar"
            aria-controls="navbarToggleLanguageBar"
            aria-expanded="false"
            translate="no"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <i className="fa-solid fa-earth-americas mx-2"></i>
          </a>
        </li>
        <li className="nav-item dropdown dropdown-lang" id="select-language">
          <a
            className="nav-link dropdown-toggle"
            rel="noopener noreferrer"
            href="#"
            id="navbarScrollingDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            translate="no"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <i className="fa-solid fa-language"></i> {selectedLang.name}
          </a>
          <ul
            className="dropdown-menu"
            aria-labelledby="navbarScrollingDropdown"
          >
            {LANGUAGES[language || "en"]
              ?.filter((lang) => lang.code !== selectedLang.code)
              ?.map((lang, index) => (
                <li
                  key={index}
                  className="dropdown-item"
                  translate="no"
                  onClick={() => {
                    handleLanguageChange(lang);
                  }}
                >
                  {lang.name}
                </li>
              ))}
          </ul>
        </li>

        {user?.uid || moderator?.id ? (
          <li className="nav-item">
            {/* <OverlayTrigger
              trigger="click"
              placement={"bottom"}
              rootClose={true}
              show={show}
              overlay={
                <Tooltip id={`tooltip-top-profile`}>
                  <div className="menu">
                    <ul>
                      {!moderator?.id && (
                        <>
                          <li>
                            <Link id="sign-in" to="/settings" tabIndex="-1"
                            onClick={()=>setShow(false)}>
                              <i className="fa-solid fa-gear"></i>&nbsp;Profile
                            </Link>
                          </li>
                          <li>
                            <Link id="sign-in" to="/my-resources" tabIndex="-1"
                            onClick={()=>setShow(false)}>
                              <i className="fa-brands fa-blogger-b"></i>&nbsp;My
                              Blogs
                            </Link>
                          </li>
                          <li>
                            <Link id="sign-in" to="/my-campaigns" tabIndex="-1"
                            onClick={()=>setShow(false)}>
                              <i className="fa-solid fa-bullhorn"></i>&nbsp;My
                              Campaigns
                            </Link>
                          </li>
                        </>
                      )}
                      <li>
                        <Link
                          id="sign-in"
                          to="/"
                          tabIndex="-1" 
                          onClick={() => {
                            handleLogout();
                            setShow(false);
                          }}
                        >
                          <i className="fa-solid fa-right-from-bracket"></i>
                          &nbsp;Sign Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Tooltip>
              }
            >
              <div className="profile" onClick={()=>setShow(true)}>
                <div className="img-box">
                  <img
                    loading="lazy"
                    src={
                      user?.userCustomData
                        ? user?.userCustomData?.userImage
                        : moderator && moderator.id
                        ? process.env.REACT_APP_BUCKET_URL +
                            moderator?.userImageURL || MODERATOR_AVATAR_URL
                        : ""
                    }
                    alt="some user image"
                  />
                </div>
              </div>
            </OverlayTrigger> */}
            <div className="profile" onClick={() => setPopupOpen(!isPopupOpen)}>
              <div className="img-box">
                <img
                  loading="lazy"
                  src={
                    user?.userCustomData
                      ? user?.userCustomData?.userImage
                      : moderator && moderator.id && moderator?.userImageURL
                      ? process.env.REACT_APP_BUCKET_URL +
                        moderator?.userImageURL
                      : MODERATOR_AVATAR_URL
                  }
                  alt="some user image"
                />
              </div>
            </div>
            <div className={`menu ${isPopupOpen ? "open" : ""}`}>
              <ul className=".popup_content">
                {!moderator?.id && (
                  <>
                    <li>
                      <Link
                        id="sign-in"
                        to="/settings"
                        tabIndex="-1"
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                      >
                        <i className="fa-solid fa-gear"></i>&nbsp;Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        id="sign-in"
                        to="/create-resource"
                        tabIndex="-1"
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                      >
                        <i className="fa-solid fa-plus"></i>
                        &nbsp;Create Resource
                      </Link>
                    </li>
                    <li>
                      <Link
                        id="sign-in"
                        to="/my-resources"
                        tabIndex="-1"
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                      >
                        <i className="fa-brands fa-blogger-b"></i>&nbsp;My
                        Resources
                      </Link>
                    </li>
                    <li>
                      <Link
                        id="sign-in"
                        to="/my-campaigns"
                        tabIndex="-1"
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                      >
                        <i className="fa-solid fa-bullhorn"></i>&nbsp;My
                        Petitions
                      </Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    id="sign-in"
                    to="/"
                    tabIndex="-1"
                    onClick={() => {
                      setPopupOpen(false);
                      handleLogout();
                    }}
                  >
                    <i className="fa-solid fa-right-from-bracket"></i>
                    &nbsp;Sign Out
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        ) : (
          <>
            <li className="nav-item">
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {navbar ? navbar[8]?.title : "Sign Up"}
                  </Tooltip>
                }
              >
                <Link
                  id="sign-up"
                  className="nav-link"
                  to="/register"
                  tabIndex="-1"
                >
                  <i className="fa-sharp fa-solid fa-user-plus"></i>
                </Link>
              </OverlayTrigger>
            </li>{" "}
            <li className="nav-item">
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {navbar ? navbar[7]?.title : "Sign In"}
                  </Tooltip>
                }
              >
                <Link
                  id="sign-in"
                  className="nav-link"
                  to="/login"
                  tabIndex="-1"
                >
                  <i className="fa-solid fa-user-lock"></i>
                </Link>
              </OverlayTrigger>
            </li>
          </>
        )}
        {/* <li className="nav-item">
          <div className="header-search" id="header-search">
            <input type="text" placeholder="Search" />
            <button
              onClick={() => {
                document
                  .getElementById("header-search")
                  .classList.toggle("active");
              }}
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </li> */}
      </ul>
    </div>
  );
};

const Navbar = () => {
  const { logOut, user, moderator } = useUserAuth();
  const { language, setLanguage } = useLanguage();
  const { navData, theme } = usePreFetchData();
  const navigate = useNavigate();
  const [dft, setDFT] = useState(
    LANGUAGES[language || "en"]?.filter(
      (lang) => getCookie("googtrans") === "/auto/" + lang.code
    ) || []
  );
  const [selectedLang, setSelectedLang] = useState(
    dft.length ? dft[0] : LANGUAGES[language || "en"][0]
  );

  useEffect(() => {
    setDFT(LANGUAGES[language || "en"]);
    setSelectedLang(LANGMAP[language]);
  }, [language]);

  useEffect(() => {
    if (document.cookie.includes("googtrans")) {
      FRONTEND_BACKEND_LANGUAGE_MAP;
      const value = getCookie("googtrans")?.split("/")[2];
      setSelectedLang(LANGMAP[FRONTEND_BACKEND_LANGUAGE_MAP[value]]);
    }
  }, []);

  const [selectedCountry, setSelectedCountry] = useState("Select Country");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { setLoading } = useLoading();
  const [showCountryBar, setShowCountryBar] = useState(false);
  const [navbar, setNavbar] = useState([]);

  // const handleCountrySelect = (country) => {
  //   setSelectedCountry(country);
  // };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logOut();
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.log(error.message?.replace(/Firebase:/gi, ""));
    }
    setLoading(false);
  };

  const handleClose = (e) => {
    document.getElementsByClassName("navbar-toggler")[0].click();
  };

  const handleLanguageChange = (lang) => {
    const languageSelect = document.querySelector("select.goog-te-combo");

    if (languageSelect) {
      languageSelect.value = lang.code;
      languageSelect.dispatchEvent(new Event("change"));
      // Solution to Problem in language selection
      if (!languageSelect.value) {
        languageSelect.value = lang.code;
        languageSelect.dispatchEvent(new Event("change"));
      }
      setSelectedLang(lang);
    }
  };
  useEffect(() => {
    window.onscroll = function () {
      navbarScroll();
    };

    var navbar = document.getElementById("navbar");
    var sticky = navbar.offsetTop;

    function navbarScroll() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setNavbar(navData[language]);
  }, [navData, language]);

  return (
    <header id="navbar">
      <div className="container-fluid">
        <nav
          className="navbar navbar-expand-xl navbar-light"
          style={{ width: "100%" }}
        >
          <button
            className="navbar-toggler"
            id="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              handleClose();
            }}
            style={{ padding: "-0.75rem .75rem" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {windowWidth <= 1200 && (
            <SideNavBtns
              selectedLang={selectedLang}
              // selectedCountry={selectedCountry}
              // handleCountrySelect={handleCountrySelect}
              handleLanguageChange={handleLanguageChange}
              style={{
                top: "0px",
                right: "0px",
                position: "absolute",
                padding: ".25rem .75rem",
              }}
              navbar={navbar}
              user={user}
              moderator={moderator}
              handleLogout={handleLogout}
              setShowCountryBar={setShowCountryBar}
              showCountryBar={showCountryBar}
            />
          )}
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav me-auto my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                {navbar && (
                  <NavLinks
                    handleClose={handleClose}
                    linkText={navbar[0]?.title}
                    linkKey={navbar[0]?.id}
                  />
                )}
              </li>
              <li className="nav-item">
                {navbar && (
                  <NavLinks
                    handleClose={handleClose}
                    linkText={navbar[1]?.title}
                    linkKey={navbar[1]?.id}
                  />
                )}
              </li>
              <li className="nav-item dropdown" id="media_step">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  rel="noopener noreferrer"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  translate="no"
                >
                  {navbar && navbar[2]?.title}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  {navbar &&
                    navbar[2]?.child.length > 0 &&
                    navbar[2]?.child.map((item) => (
                      <li key={item.id}>
                        <NavLinks
                          handleClose={handleClose}
                          linkText={item.title}
                          linkKey={item.id}
                        />
                      </li>
                    ))}
                </ul>
              </li>
              <li className="nav-item dropdown" id="ovoc_in_action_step">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  rel="noopener noreferrer"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  translate="no"
                >
                  {navbar && navbar[3]?.title}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  {navbar &&
                    navbar[3]?.child.length > 0 &&
                    navbar[3]?.child.map((item) => (
                      <li key={item.id}>
                        <NavLinks
                          handleClose={handleClose}
                          linkText={item.title}
                          linkKey={item.id}
                        />
                      </li>
                    ))}
                </ul>
              </li>
              <li className="nav-item dropdown" id="campaigns_step">
                <a
                  className="nav-link dropdown-toggle"
                  rel="noopener noreferrer"
                  href="#"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  translate="no"
                >
                  {navbar && navbar[4]?.title}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  <li>
                    <NavLinks
                      handleClose={handleClose}
                      linkText={navbar && navbar[4]?.child[0]?.title}
                      linkKey={navbar && navbar[4]?.child[0]?.id}
                    />
                  </li>
                  <li>
                    <NavLinks
                      handleClose={handleClose}
                      linkText={navbar && navbar[4]?.child[1]?.title}
                      linkKey={navbar && navbar[4]?.child[1]?.id}
                    />
                  </li>
                  <li>
                    <NavLinks
                      handleClose={handleClose}
                      linkText={navbar && navbar[4]?.child[2]?.title}
                      linkKey={navbar && navbar[4]?.child[2]?.id}
                    />
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown" id="learning_resources_step">
                <a
                  className="nav-link dropdown-toggle"
                  rel="noopener noreferrer"
                  href="#"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  translate="no"
                >
                  {navbar && navbar[5]?.title}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  {navbar &&
                    navbar[5]?.child.length > 0 &&
                    navbar[5]?.child.map((item) => (
                      <li key={item.id}>
                        <NavLinks
                          handleClose={handleClose}
                          linkText={item.title}
                          linkKey={item.id}
                        />
                      </li>
                    ))}
                </ul>
              </li>
              <li className="nav-item">
                {navbar && (
                  <NavLinks
                    handleClose={handleClose}
                    linkText={navbar && navbar[6]?.title}
                    linkKey={navbar && navbar[6]?.id}
                  />
                )}
              </li>
            </ul>
          </div>
        </nav>
        {windowWidth > 1200 && (
          <SideNavBtns
            selectedLang={selectedLang}
            // selectedCountry={selectedCountry}
            // handleCountrySelect={handleCountrySelect}
            handleLanguageChange={handleLanguageChange}
            navbar={navbar}
            user={user}
            moderator={moderator}
            handleLogout={handleLogout}
            setShowCountryBar={setShowCountryBar}
            showCountryBar={showCountryBar}
            style={{ position: "relative" }}
            SideNavBtns={SideNavBtns}
          />
        )}
      </div>
      <div
        className="lang-slide collapse"
        id="navbarToggleLanguageBar"
        style={{
          position: "relative",
        }}
      >
        <div className="panel">
          <div style={{ width: "100%" }}>
            <div className="text-right">
              <div className="text-center space--xxs language-choose">
                <div className="container">
                  <div>
                    <h4 className="pt-4 mb-0">Choose your Country</h4>
                  </div>
                  <div className="row px-1 pb-3 d-flex justify-content-around align-items-center">
                    <div
                      className="lang-card"
                      onClick={() => setLanguage("en")}
                    >
                      <a
                        href="#"
                        className="block"
                        rel="noopener noreferrer"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="feature boxed boxed--border border--round m-0 lang-box">
                          <img
                            loading="lazy"
                            src={"/img/asia_icon.png"}
                            alt=""
                            style={{
                              filter:
                                language === "en" && theme[language]?.langCard,
                            }}
                          />
                          <span className="h5 color--primary mt-2">
                            <p className="menu-hover" translate="no">
                              HOME
                            </p>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div
                      className="lang-card"
                      onClick={() => setLanguage("bangla")}
                    >
                      <a
                        href="#"
                        className="block"
                        rel="noopener noreferrer"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="feature boxed boxed--border border--round m-0 lang-box">
                          <img
                            loading="lazy"
                            src={"/img/bangladesh_icon.png"}
                            alt=""
                            style={{
                              filter:
                                language === "bangla" &&
                                theme[language]?.langCard,
                            }}
                          />
                          <span className="h5 color--primary mt-2">
                            <p className="menu-hover" translate="no">
                              BANGLADESH
                            </p>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div
                      className="lang-card"
                      onClick={() => setLanguage("nep")}
                    >
                      <a
                        href="#"
                        className="block"
                        rel="noopener noreferrer"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="feature boxed boxed--border border--round m-0 lang-box">
                          <img
                            loading="lazy"
                            src={"/img/nepal_icon.png"}
                            alt=""
                            style={{
                              filter:
                                language === "nep" && theme[language]?.langCard,
                            }}
                          />
                          <span className="h5 color--primary mt-2">
                            <p className="menu-hover" translate="no">
                              NEPAL
                            </p>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div
                      className="lang-card"
                      onClick={() => setLanguage("thai")}
                    >
                      <a
                        href="#"
                        className="block"
                        rel="noopener noreferrer"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="feature boxed boxed--border border--round m-0 lang-box">
                          <img
                            loading="lazy"
                            src={"/img/thailand_icon.png"}
                            height="55"
                            alt=""
                            style={{
                              filter:
                                language === "thai" &&
                                theme[language]?.langCard,
                            }}
                          />
                          <span className="h5 color--primary mt-2">
                            <p className="menu-hover" translate="no">
                              THAILAND
                            </p>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
