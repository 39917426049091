import React, { createContext, useContext, useEffect, useState } from "react";
import { FILTERS, LANGMAP } from "../utility/constants";
import axiosInstance from "../utility/axios";
const LanguageContext = createContext();

const handleLanguageChange = (lang) => {
  const languageSelect = document.querySelector("select.goog-te-combo");
  if (languageSelect) {
    languageSelect.value = lang.code;
    languageSelect.dispatchEvent(new Event("change"));
    // Solution to Problem in language selection
    if (!languageSelect.value) {
      languageSelect.value = lang.code;
      languageSelect.dispatchEvent(new Event("change"));
    }
  }
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("PILangPref") || "en"
  );
  const [countryMap, setCountryMap] = useState({});

  useEffect(() => {
    axiosInstance.get("/user/country/list").then((res) => {
      const countries = res.data || [];
      const result = {};

      for (const key in FILTERS) {
        if (key === "en") continue;
        const regex = new RegExp(FILTERS[key], "i"); // Case-insensitive regex
        const matchingObject = countries?.find((obj) => regex.test(obj.name));
        if (matchingObject) {
          result[key] = matchingObject.id;
        }
      }
      setCountryMap(result);
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("PILangPref", language);
    var iframe =
      document.getElementsByClassName("goog-te-banner-frame")[0] ||
      document.getElementById(":1.container");
    if (!iframe) {
      handleLanguageChange(LANGMAP[language]);
      return;
    }

    // search all buttons from the retrieved iframe
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var restore_el = innerDoc.getElementsByTagName("button");

    // fire `click` event on the `restore` button, that `Shows the origin`
    for (var i = 0; i < restore_el.length; i++) {
      if (restore_el[i].id.indexOf("restore") >= 0) {
        restore_el[i].click();
        handleLanguageChange(LANGMAP[language]);
        return;
      }
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, countryMap }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
