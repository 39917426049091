import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Compressor from "compressorjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
} from "react-bootstrap";
import { useUserAuth } from "../context/UserAuthContext";
import axiosInstance from "../utility/axios";
import { useLoading } from "../context/LoadingContext";
import Notification from "../components/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../utility/helpers";
import CampaignPreview from "./CampaignPreview";

export default function CreateCampaign({ countries }) {
  const { user } = useUserAuth();
  const [form, setForm] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
    tag: [],
    comments: [],
    category: "",
    bannerImage: "",
    bannerImageURL: "",
    bannerImageExt: "",
    bannerImageId: "",
    description: "",
    countryIds: [],
    publishStage: undefined,
    count: "",
    host: "",
    email: "",
    contact: "",
    target: 0,
    message: "",
  });
  const [fileError, setFileError] = useState("");
  const docs_category = ["Knowledge", "Information", "Global", "General"];
  const [tags, setTags] = useState([]);
  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });
  const [notificationData, setNotificationData] = useState([]);
  const { setLoading } = useLoading();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get("id");
    const type = query.get("type");
    if (id && type === "petition") {
      fetchCampaignData(id);
    } else {
      setForm({
        id: "",
        title: "",
        start: "",
        end: "",
        tag: [],
        comments: [],
        category: "",
        bannerImage: "",
        bannerImageURL: "",
        bannerImageExt: "",
        bannerImageId: "",
        description: "",
        countryIds: [],
        publishStage: undefined,
        count: "",
        host: "",
        email: "",
        contact: "",
        target: 0,
        message: "",
      });
    }
    updateTags();
  }, [user, location.search]);

  const fetchCampaignData = async (id) => {
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    if (idToken) {
      await axiosInstance
        .get(`/website/get/campaign-data/${id}/${user?.userCustomData?.id}`, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setForm({
              id: res.data.campaign.id,
              title: res.data.campaign.title,
              start: res.data.campaign.start,
              end: res.data.campaign.end,
              tag: res.data.campaign.tags?.map((tag) => tag.id),
              ptags: res.data.campaign.tags,
              bannerImage: "",
              bannerImageURL: res.data.campaign.bannerImageURL,
              bannerImageExt: res.data.campaign.bannerImageExt,
              bannerImageId: res.data.campaign.bannerImageId,
              bannerImageName: res.data.campaign.bannerImageName,
              description: res.data.campaign.description,
              countryIds: res.data.campaign.countries,
              comments: res.data.campaign.comments,
              category: res.data.campaign.category,
              publishStage: res.data.campaign.publishStage,
              count: res.data.campaign.count,
              authorId: res.data.campaign.authorId,
              author: res.data.campaign.author,
              email: res.data.campaign.email,
              host: res.data.campaign.host,
              contact: res.data.campaign.contact,
              target: res.data.campaign.target,
              message: res.data.campaign.message,
            });
          }
        });
    }
  };

  const handleBack = () => {
    const query = new URLSearchParams(location.search);
    const isEdit = query.get("id");
    if (isEdit) {
      navigate("/my-resources");
    } else {
      navigate("/");
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setForm({ ...form, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setForm({ ...form, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        await new Compressor(file, {
          quality: 0.6,
          success(result) {
            const compressedFile = new File([result], file.name, {
              type: file.type,
            });
            setForm({ ...form, [e.target.name]: compressedFile });
          },
          error(err) {
            setFileError({ ...fileError, [e.target.name]: err });
          },
        });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };

  const handleContentChange = (e, editor) => {
    setForm((prev) => ({ ...prev, description: editor.getData() }));
  };

  const handleDropdownSelect = (e, selectedCountry) => {
    if (user?.userCustomData.countryId !== selectedCountry.id) {
      let prevSelected = form?.countryIds || [];
      let selectedCountries = [];
      // Check if the country is already selected
      const isSelected = prevSelected.filter(
        (country) => country.id === selectedCountry.id
      );
      // If the country is already selected, remove it from the selected list
      if (isSelected.length) {
        selectedCountries = prevSelected.filter(
          (country) => country.id !== selectedCountry.id
        );
      } else {
        // If the country is not selected, add it to the selected list
        selectedCountries = [...prevSelected, selectedCountry];
      }
      handleChange({
        target: { name: "countryIds", value: selectedCountries },
      });
    }
  };
  const handleTagChange = (e) => {
    let currID = parseInt(e.target.value);
    if (form.tag.some((t) => t === currID)) {
      setForm((prevForm) => ({
        ...prevForm,
        tag: form.tag.filter((t) => t !== currID),
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,

        tag: [...form.tag, currID],
      }));
    }
  };

  const DropdownSearch = React.forwardRef((props, ref) => {
    const { children, style, className, "aria-labelledby": labeledBy } = props;
    const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ position: "sticky", top: "0px" }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child) => {
            return (
              !value ||
              child.props?.children?.props?.children[1]
                .toLowerCase()
                .startsWith(value)
            );
          })}
        </ul>
      </div>
    );
  });

  useEffect(() => {
    let selectedCountries = [];
    if (countries.length) {
      if (
        form.countryIds.find(
          (country) => country.id === user?.userCustomData.countryId
        ) ||
        !user?.userCustomData.countryId
      ) {
        selectedCountries = form.countryIds;
      } else {
        const id = user?.userCustomData.countryId;
        const name = countries.find((c) => c.id === id)?.name;
        selectedCountries = [
          ...form.countryIds,
          {
            id,
            name,
          },
        ];
      }
      handleChange({
        target: { name: "countryIds", value: selectedCountries },
      });
    }
  }, [form.countryIds, countries]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("start", form.start);
    formData.append("end", form.end);
    formData.append("category", form.category);
    formData.append("description", form.description);
    formData.append("bannerImage", form.bannerImage);
    formData.append("tagIds", [...form.tag]);
    formData.append("host", form.host);
    formData.append("contact", form.contact);
    formData.append("email", form.email);
    formData.append("target", form.target);
    formData.append("message", form.message);
    formData.append(
      "countryIds",
      JSON.stringify(form.countryIds.map((country) => country.id))
    );

    if (form.id && idToken) {
      //update
      formData.append("bannerImageId", form.bannerImageId);
      axiosInstance
        .patch(
          `/website/edit/campaign/${form.id}/${user?.userCustomData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + idToken,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setMsg({
              message: [],
              variant: "danger",
            });
            navigate("/my-resources");
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }
    //create
    else if (idToken) {
      await axiosInstance
        .post(
          `/website/campaign/create/${user?.userCustomData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + idToken,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // setEditCampaign("");
            setMsg({
              message: [],
              variant: "danger",
            });
            navigate("/my-resources");
          } else {
            if (res.data.message === "Validation error")
              setMsg({
                message: res.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (res.data.message === "DB Error")
              setMsg({ message: [...res.data.error], variant: "danger" });
            else setMsg({ message: [res?.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: res.data.message,
              status: res.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }
  };

  const updateTags = () => {
    axiosInstance
      .get(`/campaign/get/tags/readAll`)
      .then((response) => {
        if (response.status === 200) {
          setTags([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err, "getting tags");
      });
  };

  const PreviewModal = ({ show, setShow, form, tags }) => {
    return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Resource Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <CampaignPreview form={form} tags={tags} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <PreviewModal show={show} setShow={setShow} form={form} tags={tags} />
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <div className="form_top">
        <div className="editform">
          <h2></h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <button className="top_btn" onClick={() => setShow(true)}>
              Preview
            </button>
          </div>
          <div>
            <button className="top_btn" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div>
            <button className="top_btn" onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 left" style={{ backgroundColor: "white" }}>
          <div className="blog_title">
            <h4>Title</h4>
            {form.publishStage != 2 && <span>*Required </span>}
            <input
              type="text"
              className="form-control"
              id="blogTitle"
              name="title"
              placeholder=""
              required
              value={form.title}
              onChange={handleChange}
              disabled={form.publishStage === 2}
            />
          </div>
          <div className="banner_image">
            <h4>Upload Banner Image</h4>
            {form.publishStage != 2 && (
              <span>*File can have max size of 5MB </span>
            )}
            <div
              className="image-uploader"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                border: "3px dashed #ccc",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              <label htmlFor="bannerImage" className="upload-label">
                <span className="upload-text">
                  {form.bannerImage || form.bannerImageName
                    ? typeof form.bannerImage === "string"
                      ? form.bannerImageName
                      : form.bannerImage.name
                    : "Choose from files"}
                </span>{" "}
                {form.bannerImage && (
                  <button
                    style={{
                      padding: "0px .5em",
                      marginLeft: "1em",
                    }}
                    className="remove-button"
                    type="button"
                    onClick={() => removeSelectedFile("bannerImage")}
                  >
                    X
                  </button>
                )}
              </label>

              <input
                type="file"
                id="bannerImage"
                accept=".jpg,.jpeg,.png"
                multiple={false}
                // style={{ display: "none" }}
                name="bannerImage"
                onChange={handleImageChange}
                disabled={form.publishStage === 2}
              />
            </div>
            <div>
              {fileError && fileError?.bannerImage && (
                <Alert variant="danger">{fileError?.bannerImage}</Alert>
              )}
            </div>
          </div>
          <div style={{ padding: "0px 2rem" }}>
            <div className="post_date">
              <h4>Date of Post:-</h4>
              <h4>{form.start && formatDate(form.start)}</h4>
            </div>
            <div className="closure_date">
              <h4>Date of Closure:-</h4>
              <h4>{form.end && formatDate(form.end)}</h4>
            </div>
            <div className="host">
              <h4>host:-</h4>
              <h4>
                {" "}
                <input
                  type="text"
                  name="host"
                  value={form.host}
                  onChange={handleChange}
                  disabled={form.publishStage === 2}
                />
              </h4>
            </div>
            <div className="contact">
              <h4>contact:-</h4>
              <h4>
                {" "}
                <input
                  type="text"
                  name="contact"
                  value={form.contact}
                  onChange={handleChange}
                  disabled={form.publishStage === 2}
                />
              </h4>
            </div>
            <div className="email">
              <h4>email:-</h4>
              <h4>
                <input
                  type="text"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  disabled={form.publishStage === 2}
                />
              </h4>
            </div>
            <div className="target">
              <h4>target:-</h4>
              <h4>
                <input
                  type="number"
                  name="target"
                  value={form.target}
                  onChange={handleChange}
                  disabled={form.publishStage === 2}
                />
              </h4>
            </div>
          </div>
          <div className="description_wrapper ">
            <h4>Description</h4>
            {form.publishStage != 2 && <span>*Required</span>}
            <div className="row">
              <CKEditor
                editor={Editor}
                data={form.description}
                onChange={handleContentChange}
                onReady={(editor) => {
                  editor.ui.view.toolbar.items.get(
                    11
                  ).buttonView.isVisible = false;
                  // editor.ui.view.toolbar.items.get(16).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(31).isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    32
                  ).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    33
                  ).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    34
                  ).buttonView.isVisible = false;
                  editor.ui.view.toolbar.items.get(
                    35
                  ).buttonView.isVisible = false;
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
                disabled={form.publishStage === 2}
              />
            </div>
          </div>
        </div>
        <div
          className="col-md-4"
          style={{ position: "relative", backgroundColor: "white" }}
        >
          <div className="editform_right">
            <div className="country marginB">
              <h4>Country</h4>
              {form.publishStage != 2 && (
                <p style={{ margin: "0" }}>*Required</p>
              )}
              {form.publishStage == 2 ? (
                <>
                  {form.countryIds.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={form?.countryIds.length === countries.length}
                    onChange={() => {
                      let selectedCountries =
                        form?.countryIds.length === countries.length
                          ? []
                          : countries;
                      handleChange({
                        target: {
                          name: "countryIds",
                          value: selectedCountries,
                        },
                      });
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={form?.countryIds.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                    />
                  ))}
                </DropdownButton>
              )}
            </div>
            <div className="Category marginB">
              <h4>Category</h4>
              {form.publishStage != 2 && <span>*Required</span>}
              <DropdownButton
                variant="outline-secondary"
                title={form.category ? form.category : "Select Category"}
                onSelect={(e) => {
                  handleChange({
                    target: { name: "category", value: e },
                  });
                }}
                id="input-group-dropdown-1"
                disabled={form.publishStage === 2}
              >
                {docs_category.map((docsCategory, index) => (
                  <Dropdown.Item eventKey={docsCategory} key={index}>
                    {docsCategory}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="p-1 marginB">
              <h4 className="sub-heading">Start Date</h4>
              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="start"
                // min={moment().local().format("YYYY-MM-DD")}
                placeholder=""
                value={
                  form.start && moment(form.start).local().format("YYYY-MM-DD")
                }
                onChange={handleChange}
                disabled={form.publishStage === 2}
              />
            </div>
            <div className="p-1 marginB">
              <h4 className="sub-heading">End Date</h4>
              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="end"
                placeholder=""
                // min={form.start}
                value={
                  form.end && moment(form.end).local().format("YYYY-MM-DD")
                }
                onChange={handleChange}
                disabled={form.publishStage === 2}
              />
            </div>
            <div className="p-1 marginB">
              <h4 className="sub-heading">Tags</h4>
              {form.publishStage === 2 ? (
                <>
                  {tags.map((tag) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={tag.id}
                    >
                      {tag.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="input-group-dropdown-1"
                    >
                      {"Select Tags"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={DropdownSearch}>
                      {tags.map((tag, index) => (
                        <Dropdown.Item key={index}>
                          <div style={{ display: "flex" }}>
                            <input
                              className="me-2"
                              type="checkbox"
                              value={tag.id}
                              onChange={handleTagChange}
                              checked={form.tag?.includes(tag.id)}
                            />
                            {tag.name}
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
            <div className="">
              <h4 className="sub-heading">why it’s important </h4>
              <div className="mesg_input">
                <input
                  value={form.message}
                  name="message"
                  type="text"
                  onChange={handleChange}
                  disabled={form.publishStage === 2}
                ></input>
              </div>
            </div>
          </div>
          <div
            style={{
              display: form.publishStage === 2 ? "flex" : "none",
            }}
          >
            <Card border="primary" style={{ margin: "auto" }}>
              <Card.Header>{"Please Wait!!!"}</Card.Header>
              <Card.Body>
                <Card.Text as="div">{"Blog is pending for approval"}</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div>
            {msg.message.length > 0 &&
              msg.message.map((m, index) => (
                <Alert key={index} variant={msg.variant}>
                  {m}
                </Alert>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
