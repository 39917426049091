const LANGMAP = {
  en: {
    name: "ENG",
    code: "en",
  },
  nep: {
    name: "NEP",
    code: "ne",
  },
  bangla: {
    name: "BEN",
    code: "bn",
  },
  thai: {
    name: "THA",
    code: "th",
  },
};

const LANGUAGES = {
  en: [
    {
      name: "ENG",
      code: "en",
    },
    {
      name: "BEN",
      code: "bn",
    },
    {
      name: "NEP",
      code: "ne",
    },
    {
      name: "THA",
      code: "th",
    },
  ],
  bangla: [
    {
      name: "ENG",
      code: "en",
    },
    {
      name: "BEN",
      code: "bn",
    },
  ],
  nep: [
    {
      name: "ENG",
      code: "en",
    },
    {
      name: "NEP",
      code: "ne",
    },
  ],
  thai: [
    {
      name: "ENG",
      code: "en",
    },
    {
      name: "THA",
      code: "th",
    },
  ],
};

const FRONTEND_BACKEND_LANGUAGE_MAP = {
  bn: "bangla",
  en: "en",
  ne: "nep",
  th: "thai",
};

const LANGUAGE_CODES = {
  Bangladesh: "BGD",
  Nepal: "NEP",
  Thailand: "THAI",
  "ASIA (APAC Region)": "ENG",
};

const FILTERS = {
  bangla: "bang",
  en: "",
  nep: "nep",
  thai: "thai",
};

const LANGUAGE_COUNTRY_MAP = {
  bangla: "Bangladesh",
  nep: "Nepal",
  thai: "Thailand",
};

export {
  LANGUAGES,
  LANGUAGE_CODES,
  FILTERS,
  LANGUAGE_COUNTRY_MAP,
  LANGMAP,
  FRONTEND_BACKEND_LANGUAGE_MAP,
};
