const THEME = {
  en: {
    general: "#0072ce",
    langCard:
      "invert(48%) sepia(67%) saturate(3273%) hue-rotate(163deg) brightness(97%) contrast(101%)",
  },
  bangla: {
    general: "#006400",
    langCard:
      "invert(20%) sepia(90%) saturate(1535%) hue-rotate(98deg) brightness(98%) contrast(105%)",
  },
  nep: {
    general: "#8B0000",
    langCard:
      "invert(10%) sepia(70%) saturate(5970%) hue-rotate(22deg) brightness(93%) contrast(130%)",
  },
  thai: {
    general: "#000080",
    langCard:
      "invert(12%) sepia(84%) saturate(3571%) hue-rotate(233deg) brightness(75%) contrast(122%)",
  },
};

export default THEME;
