import React from "react";
import Card from "./Card";
import { extractShortDescription, formatDate } from "../utility/helpers";

export default function NewsPreview({ form, tags }) {
  return (
    <div id="preview">
      <Card
        title={form.title}
        img={
          typeof form.bannerImageURL === "string" && form.bannerImageURL.length
            ? process.env.REACT_APP_BUCKET_URL + form.bannerImageURL
            : typeof form.bannerImage === "object" && form.bannerImage !== null
            ? URL.createObjectURL(form.bannerImage)
            : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
        }
        date={formatDate(form.date)}
        category={form.category}
        description={extractShortDescription(form.content, 50)}
        author={form?.authorName}
        link={""}
      />
      {!form.isExternal ? (
        <div className="">
          <div className="smile-photo">
            <img
              loading="lazy"
              src={
                typeof form.bannerImageURL === "string" &&
                form.bannerImageURL.length
                  ? process.env.REACT_APP_BUCKET_URL + form.bannerImageURL
                  : typeof form.bannerImage === "object" &&
                    form.bannerImage !== null
                  ? URL.createObjectURL(form.bannerImage)
                  : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
              }
              alt="News Image"
            />
          </div>
          <div className="every-one">
            <h2>{form.title}</h2>
            <div className="posted-date">
              <i className="fa-solid fa-calendar-days"></i>
              <span>
                Posted On: {formatDate(form.date)} | Posted By:
                <a href="#" style={{ pointerEvents: "none" }}>
                  {form?.authorName}
                </a>
              </span>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: form.content }}
              className="ck-content"
            ></div>
          </div>
          <div className="dolor-blog">
            {form?.ptags
              ? form.ptags?.map((tag, index) => (
                  <div key={index}>
                    <p>
                      <a href="#" style={{ pointerEvents: "none" }}>
                        {tag.name}
                      </a>
                    </p>
                  </div>
                ))
              : tags
                  ?.map((tag, index) => {
                    if (form.tag.some((id) => tag.id === id)) {
                      return (
                        <div key={index}>
                          <p>
                            <a href="#" style={{ pointerEvents: "none" }}>
                              {tag.name}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })
                  .filter(Boolean)}
          </div>
          {form.authorName && (
            <div className="ceo-founder">
              <div className="row">
                <div className="col-sm-3 fec-twi-lin">
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      loading="lazy"
                      src={
                        typeof form.authorImage === "string" &&
                        form.authorImage?.length
                          ? process.env.REACT_APP_BUCKET_URL + form.authorImage
                          : typeof form.authorImage === "object" &&
                            form.authorImage !== null
                          ? URL.createObjectURL(form.authorImage)
                          : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                      }
                      alt="user img"
                      style={{
                        width: "100%",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-9 john-doe">
                  <h4>
                    <a href="#" style={{ pointerEvents: "none" }}>
                      {form.authorName}
                    </a>
                  </h4>
                  <p>{form?.authorDescription}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Alert key="info" variant="info">
            News is external <br /> Please Visit:{" "}
            <a href={form.newsLink} target="_blank">
              {form.newsLink}{" "}
            </a>
          </Alert>
        </div>
      )}
    </div>
  );
}
