import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const ArticleDetails = lazy(() => import("./pages/ArticleDetails"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Campaigns = lazy(() => import("./pages/Campaigns"));
const MyCampaigns = lazy(() => import("./pages/MyCampaigns"));
const CampaignDetails = lazy(() => import("./pages/CampaignDetails"));
const CaseStories = lazy(() => import("./pages/CaseStories"));
const CaseDetails = lazy(() => import("./pages/CaseDetails"));
const ChatBoard = lazy(() => import("./pages/ChatBoard"));
const Contact = lazy(() => import("./pages/Contact"));
const Copyright = lazy(() => import("./pages/Copyright"));
const Courses = lazy(() => import("./pages/Courses"));
const CourseDetails = lazy(() => import("./pages/CourseDetails"));
const Documents = lazy(() => import("./pages/Documents"));
const Events = lazy(() => import("./pages/Events"));
const EventDetails = lazy(() => import("./pages/EventDetails"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const FAQ = lazy(() => import("./pages/FAQ"));
const Media = lazy(() => import("./pages/Media"));
const Login = lazy(() => import("./pages/Login"));
const News = lazy(() => import("./pages/News"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Register = lazy(() => import("./pages/Register"));
const Reports = lazy(() => import("./pages/Reports"));
const Terms = lazy(() => import("./pages/Terms"));
const Tools = lazy(() => import("./pages/Tools"));
const Webinars = lazy(() => import("./pages/Webinars"));
const WebinarsDetails = lazy(() => import("./pages/WebinarDetails"));
const Videos = lazy(() => import("./pages/Videos"));
const Facebook = lazy(() => import("./pages/Facebook"));
const OnlineStreaming = lazy(() => import("./pages/OnlineStreaming"));
const OtherMedia = lazy(() => import("./pages/OtherMedia"));
const DocumentDetails = lazy(() => import("./pages/DocumentDetails"));
const LinkToOrganization = lazy(() => import("./pages/LinkToOrganization"));
const ToolDetails = lazy(() => import("./pages/ToolDetails"));
const ReportDetails = lazy(() => import("./pages/ReportDetails"));
const Advocacy = lazy(() => import("./pages/Advocacy"));
const AdvocacyDetails = lazy(() => import("./pages/AdvocacyDetails"));
const Settings = lazy(() => import("./pages/Settings"));
const MyResources = lazy(() => import("./pages/MyResources"));
const VerifyUser = lazy(() => import("./pages/VerifyUser"));
const NewsDetails = lazy(() => import("./pages/NewsDetails"));

import TopLogos from "./components/TopLogos";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import { useLoading } from "./context/LoadingContext";
import Loader from "./components/Loader";

import { signOut } from "firebase/auth";
import { app, auth } from "./firebase-config";

import { UserAuthContextProvider } from "./context/UserAuthContext";

import { getAnalytics } from "firebase/analytics";
import CreateResources from "./pages/CreateResources";

getAnalytics(app);

const App = () => {
  const { loading } = useLoading();

  useEffect(() => {
    const q = new URL(window.location);
    const moderator = q.searchParams.get("moderator");
    if (moderator) {
      localStorage.setItem("plint", "as_mdr");
      signOut(auth);
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      <TopLogos />
      <Suspense fallback={<Loader />}>
        <Router>
          {" "}
          <UserAuthContextProvider>
            <Navbar />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog-details" element={<BlogDetails />} />
              <Route path="/article-details" element={<ArticleDetails />} />
              <Route path="/blogs-articles" element={<Blogs />} />
              <Route path="/campaigns" element={<Campaigns />} />
              <Route path="/my-campaigns" element={<MyCampaigns />} />
              <Route path="/campaign-details" element={<CampaignDetails />} />
              <Route path="/case-stories" element={<CaseStories />} />
              <Route path="/case-stories-details" element={<CaseDetails />} />
              <Route path="/chatboard" element={<ChatBoard />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/copyright" element={<Copyright />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/course-details" element={<CourseDetails />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/events" element={<Events />} />
              <Route path="/event-details" element={<EventDetails />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/verify-user" element={<VerifyUser />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/media" element={<Media />} />
              <Route path="/videos" element={<Videos />} />
              <Route path="/facebook" element={<Facebook />} />
              <Route path="/online-streaming" element={<OnlineStreaming />} />
              <Route path="/other-media" element={<OtherMedia />} />
              <Route path="/login" element={<Login />} />
              <Route path="/news" element={<News />} />
              <Route path="/news-details" element={<NewsDetails />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/tools" element={<Tools />} />
              <Route path="/webinars" element={<Webinars />} />
              <Route path="/webinar-details" element={<WebinarsDetails />} />
              <Route path="/document-details" element={<DocumentDetails />} />
              <Route path="/tool-details" element={<ToolDetails />} />
              <Route path="/report-details" element={<ReportDetails />} />
              <Route path="/advocacy" element={<Advocacy />} />
              <Route path="/advocacy-details" element={<AdvocacyDetails />} />
              <Route path="/create-resource" element={<CreateResources />} />
              <Route path="/my-resources" element={<MyResources />} />
              <Route
                path="/link-to-organizations"
                element={<LinkToOrganization />}
              />
              <Route path="/settings" element={<Settings />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />{" "}
          </UserAuthContextProvider>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
