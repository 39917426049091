import React from "react";
import { Link } from "react-router-dom";

const NavLinks = ({ handleClose, linkText, linkKey }) => {
  const linkComponents = {
    home: (
      <Link
        className="nav-link"
        aria-current="page"
        to="/"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),

    about_us: (
      <Link
        className="nav-link"
        to="/about"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    gallery: (
      <Link
        className="dropdown-item"
        to="/media"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    vdos: (
      <Link
        className="dropdown-item"
        to="/videos"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    facebook: (
      <Link
        className="dropdown-item"
        to="/facebook"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    online_streaming: (
      <Link
        className="dropdown-item"
        to="/online-streaming"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    other_ovoc_media: (
      <Link
        className="dropdown-item"
        to="/other-media"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    events: (
      <Link
        className="dropdown-item"
        to="/events"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    news: (
      <Link
        className="dropdown-item"
        to="/news"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    chatboard: (
      <Link
        className="dropdown-item"
        to="/chatboard"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    webinars: (
      <Link
        className="dropdown-item"
        to="/webinars"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    join_campaign: (
      <Link
        className="dropdown-item"
        to="/campaigns"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    my_campaigns: (
      <Link
        className="dropdown-item"
        to="/my-campaigns"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    advocacy: (
      <Link
        className="dropdown-item"
        to="/advocacy"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    online_course: (
      <Link
        className="dropdown-item"
        to="/courses"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    blogs_articles: (
      <Link
        className="dropdown-item"
        to="/blogs-articles"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    tools: (
      <Link
        className="dropdown-item"
        to="/tools"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    case_stories: (
      <Link
        className="dropdown-item"
        to="/case-stories"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    research_advocacy: (
      <Link
        className="dropdown-item"
        to="/documents"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    reports: (
      <Link
        className="dropdown-item"
        to="/reports"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
    contact_us: (
      <Link
        className="nav-link"
        to="/contact"
        tabIndex="-1"
        onClick={() => {
          handleClose();
        }}
        translate="no"
      >
        {linkText}
      </Link>
    ),
  };
  return linkComponents[linkKey] || null;
};

export default NavLinks;
