import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase-config";
import axiosInstance from "../utility/axios";
import { useLoading } from "./LoadingContext";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [moderator, setModerator] = useState(null);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (localStorage.getItem("plint") === "as_mdr") {
      axiosInstance.get("/user").then((response) => {
        setModerator({ ...response?.data });
        if (!response) {
          localStorage.removeItem("plint");
        }
      });
      setUser(null);
      signOut(auth);
    }
  }, []);

  async function logInAsModerator(email, password) {
    return axiosInstance
      .post("/site/login", {
        email,
        password,
      })
      .then((response) => {
        if (response?.status === 200) {
          setModerator(response?.data?.user);
          localStorage.setItem("plint", "as_mdr");
          return;
        } else {
          throw new Error(response?.data?.error);
        }
      });
  }

  function logIn(email, password) {
    setModerator(null);
    localStorage.removeItem("plint");
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    setModerator(null);
    localStorage.removeItem("plint");
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    if (moderator?.id) {
      return new Promise(
        axiosInstance.get("/site/logout").then((res) => {
          setModerator(null);
          localStorage.removeItem("plint");
          return;
        })
      );
    } else {
      return signOut(auth);
    }
  }

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };
  const subscribeWrapper = () => {
    return onAuthStateChanged(auth, async (currentuser) => {
      if (currentuser?.uid) {
        const userData = await axiosInstance.get(
          `/website/user/${currentuser?.uid}`,
          {
            headers: {
              Authorization: "Bearer " + currentuser.accessToken,
            },
          }
        );
        setUser({
          ...currentuser,
          userCustomData: userData?.data?.user,
        });
      } else {
        setUser(currentuser);
      }
    });
  };

  useEffect(() => {
    const unsubscribe = subscribeWrapper();
    return () => {
      subscribeWrapper();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        moderator,
        logIn,
        signUp,
        logOut,
        subscribeWrapper,
        logInAsModerator,
        resetPassword,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
