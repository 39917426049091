import React, { createContext, useContext, useEffect, useState } from "react";
import { useLanguage } from "./LanguageContext";
import axiosInstance from "../utility/axios";
import THEME from "../utility/theme";

const PreFetchDataContext = createContext();

export const PreFetchDataProvider = ({ children }) => {
  const [topImages, setTopImages] = useState();
  const [countries, setCountries] = useState([]);
  const [navData, setNavData] = useState({});
  const [theme, setTheme] = useState(THEME);
  const { language } = useLanguage();

  const getNavbar = () => {
    axiosInstance
      .get("/siteData/getNavbar")
      .then((response) => {
        if (response.status === 200) {
          setNavData(response.data.response);
          setTheme(response.data.response?.theme);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTopImages = async () => {
    await axiosInstance
      .get(`/siteData/getTopImage/${language}`)
      .then((response) => {
        if (response.status === 200) {
          setTopImages(response.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountries = async () => {
    await axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  };

  useEffect(() => {
    getTopImages();
    getCountries();
    getNavbar();
  }, [language]);

  return (
    <PreFetchDataContext.Provider
      value={{ topImages, countries, navData, theme }}
    >
      {children}
    </PreFetchDataContext.Provider>
  );
};

export const usePreFetchData = () => {
  return useContext(PreFetchDataContext);
};
