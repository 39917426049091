const formatDateAndTime = (dateString) => {
  const dateObject = new Date(dateString);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[dateObject.getUTCMonth()];
  const day = dateObject.getUTCDate();
  const year = dateObject.getUTCFullYear();

  // const hours = dateObject.getUTCHours();
  // const minutes = dateObject.getUTCMinutes();
  // const ampm = hours >= 12 ? "PM" : "AM";
  // const formattedHours = hours % 12 || 12;

  // return `${month} ${day}th ${year}, ${formattedHours}:${
  //   minutes < 10 ? "0" : ""
  // }${minutes} ${ampm}`;
  const options = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  };
  const formattedHours = dateObject.toLocaleString(undefined, options);
  // return `${month} ${day}th ${year}, ${formattedHours}`;
  return `${formattedHours}`;
};

const EventPreview = ({ form }) => {
  const formattedStart = formatDateAndTime(form.start);
  const formattedEnd = formatDateAndTime(form.end);
  return (
    <div id="preview">
      <h1>{form.title}</h1>
      {form.bannerImageId && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            loading="lazy"
            src={
              process.env.REACT_APP_BUCKET_URL +
              "public/events/" +
              form.bannerImageId +
              form.bannerImageExt
            }
            style={{
              maxWidth: "100%",
              maxHeight: "200px",
            }}
            alt=""
          />
        </div>
      )}
      <div className="posted-date">
        <span>Category:</span>
        <a href="#" style={{ pointerEvents: "none" }}>
          {form.category}
        </a>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: form.description }}
        style={{ overflow: "auto", padding: "0px 15px" }}
        className="ck-content"
      ></div>
      <div className="">
        <div className="right-event-detail">
          <div className="event-date-r">
            <div>
              <img
                loading="lazy"
                src="/img/new-calender.png"
                alt="Event Calendar"
              />
            </div>
            <div>
              <p>
                Start Date & Time
                <span>{formattedStart}</span>
              </p>
            </div>
          </div>
          <div className="event-date-r">
            <div>
              <img
                loading="lazy"
                src="/img/new-calender.png"
                alt="Event Calendar"
              />
            </div>
            <div>
              <p>
                End Date & Time
                <span>{formattedEnd}</span>
              </p>
            </div>
          </div>
          <div className="event-date-r">
            <div>
              <img loading="lazy" src="/img/location.png" alt="Event Venue" />
            </div>
            <div>
              {form.isOffline ? (
                <>
                  <p>
                    Event Venue:<span>{form.place}</span>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Event Registration Link:{" "}
                    <span>
                      {" "}
                      <a href={form.place} style={{ pointerEvents: "none" }}>
                        {form.place}
                      </a>
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="may-dum">
            <div className="organizer-event">
              <h4>Organizer</h4>
              {form.phone && (
                <p>
                  <span>Phone:</span> {form.phone}
                </p>
              )}

              <p>
                <span>Email:</span> {form.email}
              </p>
              {form.website && (
                <p>
                  <span>Website:</span> {form.website}
                </p>
              )}
            </div>
          </div>
          <div className="jesus-hoiland">
            <h4>Event Host</h4>
            {form.host1Name && (
              <div className="event-date-r" id="host-speak">
                <div>
                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof form.host1Image === "string" &&
                      form.host1Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + form.host1Image
                        : typeof form.host1Image === "object" &&
                          form.host1Image !== null
                        ? URL.createObjectURL(form.host1Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    {" "}
                    <a href="" style={{ pointerEvents: "none" }}>
                      {form.host1Name}
                    </a>
                    <span>{form.host1Role}</span>{" "}
                  </p>
                  {/* <span>Host and Speaker</span> */}
                </div>
              </div>
            )}
            {form.host2Name && (
              <div className="event-date-r" id="host-speak">
                <div>
                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof form.host2Image === "string" &&
                      form.host2Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + form.host2Image
                        : typeof form.host2Image === "object" &&
                          form.host2Image !== null
                        ? URL.createObjectURL(form.host2Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    {" "}
                    <a href="" style={{ pointerEvents: "none" }}>
                      {form.host2Name}
                    </a>
                    <span>{form.host2Role}</span>{" "}
                  </p>
                </div>
              </div>
            )}

            {form.host3Name && (
              <div className="event-date-r" id="host-speak">
                <div>
                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof form.host3Image === "string" &&
                      form.host3Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + form.host3Image
                        : typeof form.host3Image === "object" &&
                          form.host3Image !== null
                        ? URL.createObjectURL(form.host3Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    {" "}
                    <a href="" style={{ pointerEvents: "none" }}>
                      {form.host3Name}
                    </a>
                    <span>{form.host3Role}</span>{" "}
                  </p>
                </div>
              </div>
            )}
            {form.host4Name && (
              <div className="event-date-r" id="host-speak">
                <div>
                  <img
                    loading="lazy"
                    className="host-event"
                    src={
                      typeof form.host4Image === "string" &&
                      form.host4Image?.length
                        ? process.env.REACT_APP_BUCKET_URL + form.host4Image
                        : typeof form.host4Image === "object" &&
                          form.host4Image !== null
                        ? URL.createObjectURL(form.host4Image)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="Event Host"
                    style={{
                      minWidth: "50px",
                      maxWidth: "50px",
                      aspectRatio: 1,
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div>
                  <p>
                    {" "}
                    <a href="" style={{ pointerEvents: "none" }}>
                      {form.host4Name}
                    </a>
                    <span>{form.host4Role}</span>{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPreview;
