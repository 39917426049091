import React from "react";
import ReactDOM from "react-dom/client";
import { LoadingProvider } from "./context/LoadingContext";
import { LanguageProvider } from "./context/LanguageContext";
import { PreFetchDataProvider } from "./context/PreFetchDataContext";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <LoadingProvider>
        <LanguageProvider>
          <PreFetchDataProvider>
            <App />
          </PreFetchDataProvider>
        </LanguageProvider>
      </LoadingProvider>
    </HelmetProvider>
  </React.StrictMode>
);
