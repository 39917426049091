import React, { useEffect, useState } from "react";
import CreateBlog from "./CreateBlog";
import CreateDocs from "./CreateDocs";
import { Tab, Tabs } from "react-bootstrap";
import CreateCampaign from "./CreateCampaign";
import axiosInstance from "../utility/axios";
import CreateAdvocacy from "./CreateAdvocacy";
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import CreateEvent from "./CreateEvent";
import CreateNews from "./CreateNews";

export default function CreateResources() {
  const [targetTab, setTargetTab] = useState("bacs");
  const [countries, setCountries] = useState([]);
  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // redirect if the user is not logged in
    if (!user) {
      navigate("/");
    }
    const query = new URLSearchParams(location.search);
    const type = query.get("type");

    const validTypes = [
      "bacs",
      "docs",
      "campaign",
      "petition",
      "event",
      "news",
    ];
    if (validTypes.includes(type)) {
      setTargetTab(type);
    }
    // const query = new URLSearchParams(location.search);
    // const type = query.get("type");
    // if (type && type === "bacs") {
    //   setTimeout(() => {
    //     setTargetTab("bacs");
    //   }, 500);
    // } else if (type && type === "docs") {
    //   setTimeout(() => {
    //     setTargetTab("docs");
    //   }, 500);
    // } else if (type && type === "campaign") {
    //   setTimeout(() => {
    //     setTargetTab("campaign");
    //   }, 500);
    // } else if (type && type === "petition") {
    //   setTimeout(() => {
    //     setTargetTab("petition");
    //   }, 500);
    // }
  }, []);

  const fetchCountries = () => {
    axiosInstance.get("/user/country/list").then((res) => {
      setCountries(res.data);
    });
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className="createBlog_wrapper">
      <div className="createBlog_form container">
        <Tabs
          id="controlled-tab-example"
          activeKey={targetTab}
          onSelect={(k) => setTargetTab(k)}
          defaultActiveKey="bacs"
          className="mb-3 .flex-column"
          fill
        >
          <Tab eventKey="bacs" title="BLOGS / ARTICLE / CASE STORIES">
            <CreateBlog countries={countries} />
          </Tab>
          <Tab eventKey="petition" title="PETITION">
            <CreateCampaign countries={countries} />
          </Tab>
          <Tab eventKey="campaign" title="CAMPAIGN">
            <CreateAdvocacy />
          </Tab>
          <Tab eventKey="event" title="EVENT">
            <CreateEvent countries={countries} />
          </Tab>
          <Tab eventKey="news" title="NEWS">
            <CreateNews countries={countries} />
          </Tab>
          <Tab eventKey="docs" title="DOCUMENTS / REPORTS / TOOLS">
            <CreateDocs />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
{
  /* <div className="custom_tab">
          <div
            className="custom_tab_child"
            onClick={() => {
              setActiveTab(1);
            }}
            style={
              activeTab == 1
                ? {
                    backgroundColor: "#E6ECFF",
                  }
                : {}
            }
          >
            <h3 style={activeTab == 1 ? { color: "black" } : {}}>
              Blogs / Article / Case Stories
            </h3>
          </div>
          <div
            className="custom_tab_child"
            onClick={() => {
              setActiveTab(2);
            }}
            style={
              activeTab == 2
                ? {
                    backgroundColor: "#E6ECFF",
                  }
                : {}
            }
          >
            {" "}
            <h3 style={activeTab == 2 ? { color: "black" } : {}}>
              Documents / Reports / Tools
            </h3>
          </div>
        </div>
        {activeTab == 1 ? <CreateBlog /> : <CreateDocs />} */
}
