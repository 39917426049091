import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  Alert,
  Badge,
  Button,
  Card,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useLoading } from "../context/LoadingContext";
import { useUserAuth } from "../context/UserAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios";
import Notification from "../components/Notification";
import Compressor from "compressorjs";
import NewsPreview from "../components/NewsPreview";

export default function CreateNews({ countries }) {
  const { user } = useUserAuth();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const [fileError, setFileError] = useState("");
  const [tags, setTags] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [show, setShow] = useState(false);
  const [newsImageTracker, setNewsImageTracker] = useState([]);
  const [msg, setMsg] = useState({
    message: [],
    variant: "danger",
  });
  const [form, setForm] = useState({
    id: "",
    title: "",
    date: "",
    tag: [],
    comments: [],
    bannerImage: "",
    bannerImageURL: "",
    bannerImageExt: "",
    bannerImageName: undefined,
    bannerImageId: "",
    content: "",
    countryIds: [],
    category: "",
    publishStage: undefined,
    authorName: "",
    isExternal: false,
    authorDescription: "",
    authorImage: "",
  });
  const news_category = ["Knowledge", "Global", "Local", "General"];

  const removeSelectedFile = (name) => {
    const fileInput = document.getElementById(name);
    fileInput.value = "";

    setForm({ ...form, [name]: "" });
    setFileError({ ...fileError, [name]: "" });
  };

  const fetchNewsData = async (id) => {
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    if (idToken) {
      await axiosInstance
        .get(`/website/get/news/${user?.userCustomData?.id}/${id}`, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setForm({
              id: res.data.news.id,
              title: res.data.news.title,
              date: res.data.news.date,
              bannerImage: "",
              bannerImageURL: res.data.news.bannerImageURL,
              bannerImageName: res.data.news.bannerImageName,
              bannerImageId: res.data.news.bannerImageId,
              bannerImageExt: res.data.news.bannerImageExt,
              category: res.data.news.category,
              content: res.data.news.content,
              newsLink: res.data.news.newsLink,
              publishStage: res.data.news.publishStage,
              tag: res.data.news.tags?.map((tag) => tag.id),
              countryIds: res.data.news.countries,
              isExternal: res.data.news.isExternal,
              author: res.data.news.authorName,
              authorName: res.data.news.authorName,
              authorDescription: res.data.news.authorDescription,
              authorImage: res.data.news.authorImageURL,
              authorId: res.data.news.publicCreatedBy,
              comment: res.data.news.comment,
            });
          } else {
            setForm({
              id: "",
              title: "",
              date: "",
              tag: [],
              comments: [],
              bannerImage: "",
              bannerImageURL: "",
              bannerImageExt: "",
              bannerImageName: undefined,
              bannerImageId: "",
              content: "",
              countryIds: [],
              category: "",
              publishStage: undefined,
              authorName: "",
              isExternal: false,
              authorDescription: "",
              authorImage: "",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            fetch(`${process.env.REACT_APP_API}/news/image/uploads`, {
              method: "post",
              body: body,
              credentials: "include",
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                setNewsImageTracker([...newsImageTracker, res.url]);
                resolve({
                  default: `${process.env.REACT_APP_BUCKET_URL}${res.url}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };
  const uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  };

  const handleContentChange = (event, editor) => {
    setForm((prevForm) => ({ ...prevForm, content: editor.getData() }));
  };
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*") && file.size <= 5000000) {
      setForm({ ...form, [e.target.name]: file });
      setFileError({ ...fileError, [e.target.name]: "" });
      try {
        const compressedBlob = await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });

        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });
        setForm({ ...form, [e.target.name]: compressedFile });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setFileError({
        ...fileError,
        [e.target.name]: "File can have max size of 5MB",
      });
    }
  };

  const handleTagChange = (e) => {
    let currID = parseInt(e.target.value);
    if (form.tag.some((t) => t === currID)) {
      setForm((prevForm) => ({
        ...prevForm,
        tag: form.tag.filter((t) => t !== currID),
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,

        tag: [...form.tag, currID],
      }));
    }
  };

  const updateTags = () => {
    axiosInstance
      .get(`/news/get/tags`)
      .then((response) => {
        if (response.status === 200) {
          setTags([...response.data?.response]);
        } else {
          // TODO
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDropdownSelect = (e, selectedCountry) => {
    if (user?.userCustomData.countryId !== selectedCountry.id) {
      let prevSelected = form?.countryIds || [];
      let selectedCountries = [];
      // Check if the country is already selected
      const isSelected = prevSelected.filter(
        (country) => country.id === selectedCountry.id
      );
      // If the country is already selected, remove it from the selected list
      if (isSelected.length) {
        selectedCountries = prevSelected.filter(
          (country) => country.id !== selectedCountry.id
        );
      } else {
        // If the country is not selected, add it to the selected list
        selectedCountries = [...prevSelected, selectedCountry];
      }
      handleChange({
        target: { name: "countryIds", value: selectedCountries },
      });
    }
  };
  const DropdownSearch = React.forwardRef((props, ref) => {
    const { children, style, className, "aria-labelledby": labeledBy } = props;
    const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ position: "sticky", top: "0px" }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child) => {
            return (
              !value ||
              child.props?.children?.props?.children[1]
                .toLowerCase()
                .startsWith(value)
            );
          })}
        </ul>
      </div>
    );
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const idToken = await user?.auth?.currentUser?.getIdToken(
      /* forceRefresh */ true
    );
    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("date", form.date);
    formData.append("tagIds", [...form.tag]);
    formData.append("bannerImage", form.bannerImage);
    formData.append("content", form.content);
    formData.append("category", form.category);
    formData.append("authorName", form.authorName);
    formData.append("authorDescription", form.authorDescription);
    formData.append("authorImage", form.authorImage);
    formData.append(
      "countryIds",
      JSON.stringify(form.countryIds.map((country) => country.id))
    );
    formData.append("isExternal", form.isExternal);

    if (form.id && idToken) {
      formData.append("bannerImageId", form.bannerImageId);
      await axiosInstance
        .patch(`/website/edit/news/${form.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setMsg({
              message: [],
              variant: "danger",
            });
            setNewsImageTracker([]);
            navigate("/my-resources");
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({
                message: [...response.data.error],
                variant: "danger",
              });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (idToken) {
      await axiosInstance
        .post(`/website/create/news/${user?.userCustomData?.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            setMsg({
              message: [],
              variant: "danger",
            });
            setNewsImageTracker([]);
            navigate("/my-resources");
          } else {
            if (response.data.message === "Validation error")
              setMsg({
                message: response.data.error?.details?.map((e) => e.message),
                variant: "danger",
              });
            else if (response.data.message === "DB Error")
              setMsg({ message: [...response.data.error], variant: "danger" });
            else
              setMsg({ message: [response.data.message], variant: "danger" });
          }
          setNotificationData([
            {
              msg: response.data.message,
              status: response.status,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLoading(false);
  };
  const handleBack = () => {
    const query = new URLSearchParams(location.search);
    const isEdit = query.get("id");
    if (isEdit) {
      navigate("/my-resources");
    } else {
      navigate("/");
    }
  };

  const PreviewModal = () => {
    return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>News Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          <NewsPreview form={form} tags={tags} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get("id");
    const type = query.get("type");
    if (id && type === "news") {
      fetchNewsData(id);
    } else {
      setForm({
        id: "",
        title: "",
        date: "",
        tag: [],
        comments: [],
        bannerImage: "",
        bannerImageURL: "",
        bannerImageExt: "",
        bannerImageName: undefined,
        bannerImageId: "",
        content: "",
        countryIds: [],
        category: "",
        publishStage: undefined,
        authorName: "",
        isExternal: false,
        authorDescription: "",
        authorImage: "",
      });
    }
  }, [user, location.search]);

  useEffect(() => {
    let selectedCountries = [];
    if (countries.length) {
      if (
        form.countryIds.find(
          (country) => country.id === user?.userCustomData.countryId
        ) ||
        !user?.userCustomData.countryId
      ) {
        selectedCountries = form.countryIds;
      } else {
        const id = user?.userCustomData.countryId;
        const name = countries.find((c) => c.id === id)?.name;
        selectedCountries = [
          ...form.countryIds,
          {
            id,
            name,
          },
        ];
      }
      handleChange({
        target: { name: "countryIds", value: selectedCountries },
      });
    }
  }, [form.countryIds, countries]);

  useEffect(() => {
    updateTags();
  }, []);

  useEffect(() => {
    newsImageTracker.forEach((imageURL, idx) => {
      if (form.content.search(imageURL) === -1) {
        setNewsImageTracker([
          ...newsImageTracker.filter((url) => url !== imageURL),
        ]);
        axiosInstance
          .post(`/news/image/remove`, {
            filePath: imageURL,
          })
          .then((response) => {})
          .catch((err) => {});
      }
    });
  }, [form.content]);

  return (
    <div>
      <Notification
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <PreviewModal />

      <div className="form_top">
        <div className="editform">
          <h2></h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <button
              className="top_btn"
              onClick={() => {
                setShow(true);
              }}
            >
              Preview
            </button>
          </div>
          <div>
            <button className="top_btn" onClick={handleSubmit}>
              {" "}
              Save
            </button>
          </div>
          <div>
            <button className="top_btn" onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-md-8 left" style={{ position: "relative" }}>
          <div className="blog_title">
            <h4>Title</h4>
            {form.publishStage != 2 && <span>*Required </span>}
            <input
              type="text"
              className="form-control"
              id="blogTitle"
              name="title"
              placeholder=""
              required
              value={form.title}
              onChange={handleChange}
              disabled={form.publishStage === 2}
            />
          </div>
          <div className="banner_image">
            <h4>Upload Banner Image</h4>
            {form.publishStage != 2 && (
              <span>*File can have max size of 5MB </span>
            )}
            <div
              className="image-uploader"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                border: "3px dashed #ccc",
                padding: "20px",
                borderRadius: "12px",
              }}
            >
              <label htmlFor="bannerImage" className="upload-label">
                <span className="upload-text">
                  {form.bannerImage || form.bannerImageName
                    ? typeof form.bannerImage === "string"
                      ? form.bannerImageName
                      : form.bannerImage?.name
                    : "Choose from files"}
                </span>{" "}
                {form.bannerImage && (
                  <button
                    style={{
                      padding: "0px .5em",
                      marginLeft: "1em",
                      position: "relative",
                      zIndex: 5,
                    }}
                    className="remove-button"
                    type="button"
                    onClick={() => removeSelectedFile("bannerImage")}
                  >
                    X
                  </button>
                )}
              </label>

              <input
                type="file"
                id="bannerImage"
                accept=".jpg,.jpeg,.png"
                multiple={false}
                style={{ opacity: 0 }}
                name="bannerImage"
                onChange={handleImageChange}
                disabled={form.publishStage === 2}
              />
            </div>
            <div>
              {fileError && fileError?.bannerImage && (
                <Alert variant="danger">{fileError?.bannerImage}</Alert>
              )}
            </div>
          </div>
          <Row>
            <div className="container m-1" style={{ paddingBottom: "1rem" }}>
              <Form.Check
                type="checkbox"
                id="default-checkbox"
                label="Add an external link instead"
                checked={form.isExternal}
                onChange={() =>
                  handleChange({
                    target: { name: "isExternal", value: !form.isExternal },
                  })
                }
                disabled={form.publishStage === 2}
              />
            </div>
          </Row>
          {!form.isExternal ? (
            <div className="description_wrapper ">
              <h4>Content</h4>
              {form.publishStage != 2 && <span>*Required </span>}
              <div className="row">
                <CKEditor
                  editor={Editor}
                  data={form.content ? form.content : ""}
                  onReady={(editor) => {
                    uploadPlugin(editor);
                  }}
                  onChange={handleContentChange}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  disabled={form.publishStage === 2}
                />
              </div>
            </div>
          ) : (
            <Row>
              <Container className="rounded editor-container p-4 m-1">
                <h2 className="sub-heading">Enter News link</h2>
                <input
                  type="text"
                  className="form-control"
                  name="newsLink"
                  placeholder=""
                  value={form.newsLink}
                  onChange={handleChange}
                  disabled={form.publishStage === 2}
                />
              </Container>
            </Row>
          )}

          <div className="content_wrapper">
            <div className="ceo-founder">
              <div className="row">
                <div
                  className="col-sm-3 fec-twi-lin"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    loading="lazy"
                    src={
                      typeof form.authorImage === "string" &&
                      form.authorImage?.length
                        ? process.env.REACT_APP_BUCKET_URL + form.authorImage
                        : typeof form.authorImage === "object" &&
                          form.authorImage !== null
                        ? URL.createObjectURL(form.authorImage)
                        : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                    }
                    alt="user img"
                    style={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      borderRadius: "50%",
                    }}
                  />
                  {form.authorImage && (
                    <button
                      style={{
                        padding: "0px .5em",
                        marginLeft: "1em",
                      }}
                      className="remove-button"
                      type="button"
                      onClick={() => removeSelectedFile("authorImage")}
                      disabled={form.publishStage === 2}
                    >
                      Remove File
                    </button>
                  )}
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    name="authorImage"
                    id="authorImage"
                    value=""
                    placeholder=""
                    onChange={handleImageChange}
                    style={{
                      display: form.authorImage ? "none" : "block",
                    }}
                    disabled={form.publishStage === 2}
                  />
                </div>
                <div className="col-sm-9 john-doe">
                  <h4>
                    <input
                      type="text"
                      name="authorName"
                      value={form.authorName}
                      placeholder="Author Name"
                      onChange={handleChange}
                      disabled={form.publishStage === 2}
                    />
                  </h4>
                  <p>
                    <textarea
                      type="text"
                      name="authorDescription"
                      value={form.authorDescription}
                      placeholder="description"
                      style={{
                        minWidth: "100%",
                      }}
                      rows={"5"}
                      onChange={handleChange}
                      disabled={form.publishStage === 2}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 right">
          <div className="editform_right">
            <div className="country marginB">
              <h4>Country</h4>
              {form.publishStage != 2 && (
                <p style={{ margin: "0" }}>*Required</p>
              )}
              {form.publishStage === 2 ? (
                <>
                  {form.countryIds.map((country) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={country.id}
                    >
                      {country.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <DropdownButton
                  variant="outline-secondary"
                  title={"Select Country"}
                  id="input-group-dropdown-1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    style={{ margin: "0px 20px" }}
                    checked={form?.countryIds.length === countries.length}
                    onChange={() => {
                      let selectedCountries =
                        form?.countryIds.length === countries.length
                          ? []
                          : countries;
                      handleChange({
                        target: {
                          name: "countryIds",
                          value: selectedCountries,
                        },
                      });
                    }}
                  />
                  {countries.map((country, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={country.name}
                      style={{ margin: "0px 20px" }}
                      checked={form?.countryIds?.some(
                        (selected) => selected?.id === country?.id
                      )}
                      onChange={(e) => {
                        handleDropdownSelect(e, country);
                      }}
                    />
                  ))}
                </DropdownButton>
              )}
            </div>
            <div className="Category marginB">
              <h4>Category</h4>
              {form.publishStage != 2 && <span>*Required </span>}
              <DropdownButton
                variant="outline-secondary"
                title={form.category ? form.category : "Select Category"}
                onSelect={(e) => {
                  handleChange({
                    target: { name: "category", value: e },
                  });
                }}
                id="input-group-dropdown-1"
                disabled={form.publishStage === 2}
              >
                {news_category.map((newsCategory, index) => (
                  <Dropdown.Item eventKey={newsCategory} key={index}>
                    {newsCategory}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="postDate_wrapper marginB">
              <h4>Post date</h4>
              {form.publishStage != 2 && <span>*Required </span>}
              <input
                type="date"
                className="form-control"
                id="blogDate"
                name="date"
                placeholder=""
                min={new Date().toISOString().split("T")[0]}
                value={form.date}
                onChange={handleChange}
                disabled={form.publishStage === 2}
              />
            </div>
            <div className="tags_wrapper marginB">
              <h4>Tags</h4>
              {form.publishStage != 2 && (
                <p style={{ margin: "0" }}>*Required </p>
              )}
              {form.publishStage === 2 ? (
                <>
                  {tags.map((tag) => (
                    <Badge
                      bg="dark"
                      text="light"
                      className="p-2 m-1"
                      key={tag.id}
                    >
                      {tag.name}
                    </Badge>
                  ))}
                </>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="input-group-dropdown-1"
                  >
                    {"Select Tags"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={DropdownSearch}>
                    {tags.map((tag, index) => (
                      <Dropdown.Item key={index}>
                        <div style={{ display: "flex" }}>
                          <input
                            className="me-2"
                            type="checkbox"
                            value={tag.id}
                            onChange={handleTagChange}
                            checked={form.tag?.includes(tag.id)}
                          />
                          {tag.name}
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <div
              style={{
                display: form.publishStage === 2 ? "flex" : "none",
              }}
            >
              <Card border="primary" style={{ margin: "auto" }}>
                <Card.Header>{"Please Wait!!!"}</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    {"News is pending for approval"}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>{" "}
          <div>
            {msg.message.length > 0 &&
              msg.message.map((m, index) => (
                <Alert key={index} variant={msg.variant}>
                  {m}
                </Alert>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
