const AVATAR_URL = [
  "https://cdn-icons-png.flaticon.com/512/6997/6997662.png",
  "https://cdn-icons-png.flaticon.com/512/4140/4140048.png",
  "https://cdn-icons-png.flaticon.com/512/4128/4128176.png",
  "https://cdn-icons-png.flaticon.com/512/4140/4140051.png",
];

const MODERATOR_AVATAR_URL =
  "https://cdn-icons-png.flaticon.com/512/8896/8896446.png";

const GENERAL_AVATAR =
  "https://cdn-icons-png.flaticon.com/512/3177/3177440.png";

export { AVATAR_URL, MODERATOR_AVATAR_URL, GENERAL_AVATAR };
